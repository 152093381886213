
//FIND THE INITIAL DEFAULT OFFERING CARD
export const findDefaultPricing=(data)=>{
    let dta={}
    data.variants.map((e,index)=>{
        if(e.default_visibility===1){
            dta={
                ...e,
                index
            } 
        }
    })
    return dta
}

export const findPricingByName=(data, name)=>{
    let dta={}
    data.variants.map((e,index)=>{
        if(e.name===name){
            dta={
                ...e,
                index
            }
        }
    })
    return dta

}

export const numDifferentiation=(val)=>{

    if(val >= 10000000) val = (val/10000000) + ' Cr';
    else if(val >= 200000) val = (val/100000) + ' Lakhs';
    else if(val == 100000) val = (val/100000) + ' Lakh';
    else if(val >= 1000) val = (val/1000) + ' K';
    return val;
}

export const numberformat = new Intl.NumberFormat();


//SLIDE TO POSITION
export const SideScroll=(element,direction,timeout,distance,step)=>{
    let scrollAmount = 0;
    var slideTimer = setInterval(function(){
      try{
          if(direction === 'left'){
              element.scrollLeft -= step;
          } else if(direction === 'right') {
              element.scrollLeft += step;
          }else{
              element.scrollTo({
                  top: 0,
                  left: distance || 0,
                  behavior: 'smooth'
                });
          }
          scrollAmount += step;
          if(scrollAmount >= distance){
              window.clearInterval(slideTimer);
          }
        }
        catch(e){
          window.clearInterval(slideTimer);
        }
    }, timeout)
}


//CAPITALIZE THE LATERS
export const CapitalizeString=(string)=>{
    try{
        return string[0].toUpperCase()+string.substring(1).toLowerCase()
    }catch(e){
        return ""
    }
}
export const setSessionPartner=(url)=>{
    if(url){
        let domain = (new URL(url));
        domain = domain.hostname.split('.');
        const domainName= domain[domain.length-2]; 
        sessionStorage.setItem('domain',domainName)
    }
   
    // sessionStorage.setItem('domain','singh')
        
}

export const convertUrlToSourceName=(url)=>{  
        let domainName=sessionStorage.getItem("domain");
         if(domainName==="godrejcapitalnirmaan" || domainName==="godrejfinance" ){
            return 'Godrej'
        }
    }

   
    

export const isSameDomain=(url)=>{
        let domainName=sessionStorage.getItem("domain");
        if(domainName==='godrejcapitalnirmaan' || domainName==='godrejfinance'){
            return true
        } else{
            return false
        }
        
    
    
   
}
