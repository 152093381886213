import React from 'react';
import './footer.css';
import { Link, withRouter } from 'react-router-dom';
import { urls } from '../../../routes/routes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {actions as user_state} from '../../../redux/ducks/state_change'
// import { navigation } from '../../../utils/Env';
import { Clevertap } from '../../../utils/Utils';
// import HomeFooterSeo from './SeoContents/Home/HomeFooterSeo';
// import GHISeo from './SeoContents/GrpHealthInsrnce/GHISeo';
import { LazyImage } from '../../../utils/LazyImage';
import { isMobile } from 'react-device-detect';

function FooterComponent(props) {

	const btnMailClickFn = () => {
		window.location = "mailto:career@onsurity.com";
	  }


	const Information=(
		<>
				<div className=" info_nm_ft_cls dispFlxContFooter">
					Information
				</div>
				<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
				  <a target='_blank' href='https://onsurity.com/about-us/' style={{color:'rgb(128 134 146)'}}>About Us</a>
				    <a title="Onsurity - Best Employee Healthcare Platform " href="https://onsurity.com/about-us/" alt="About Us" style={{color:'rgb(128 134 146)',fontSize: '0px'}}>About Us</a>
				</div>
				<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
				   {/* <Link onClick={()=>window.location.href="https://blog.onsurity.com/"} style={{color:'rgb(128 134 146)'}} >Blog</Link> */}
					  <a  target='_blank' title="Onsurity - Blog" href="https://onsurity.com/blog/" alt="Blog" style={{color:'rgb(128 134 146)',fontSize: '15px'}} >Blog</a>
				</div>
				<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
				  <a  target='_blank' href ='https://onsurity.com/contact-us/'style={{color:'rgb(128 134 146)'}} >Contact Us</a>
				     <a  target='_blank' title="Onsurity – Contact Us for Customer Support and Partnerships" href="https://onsurity.com/contact-us/" alt="Contact Us" style={{color:'rgb(128 134 146)',fontSize: '0px'}} >Contact Us</a>
				</div>
				<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
				  {/* <Link onClick={()=>window.location.href="https://faq.onsurity.com/"}  style={{color:'rgb(128 134 146)'}} >FAQ</Link> */}
					<a  target='_blank' title="Faq" href="https://onsurity.com/faq/" alt="faq" style={{color:'rgb(128 134 146)',fontSize: '15px'}} >FAQ</a>
				</div>
				{/* <div className=" ft_dtls_cnt_Cls dispFlxContFooter">
					<Link to={urls.FAQ} style={{color:'rgb(128 134 146)'}} >FAQ</Link>
				</div> */}
				{/* <div className=" ft_dtls_cnt_Cls dispFlxContFooter">
				<div onClick={btnMailClickFn} style={{color:'rgb(128 134 146)', cursor:'pointer'}} >Careers</div>
				</div> */}
				<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
				  {/* <Link onClick={()=>window.location.href="https://onsurity.kekahire.com/"} target={'_blank'}  style={{color:'rgb(128 134 146)'}} >Careers</Link> */}
					<a  target='_blank' title="Careers" href="https://forms.office.com/pages/responsepage.aspx?id=nNUjj-DjbUu6h3mc5yJrIkikiyYgjBdIpVMksxYt4VhUNERXMTlWSDdKQkdRNjM2UFMxQ0lPWkRIMS4u" alt="Careers" target='blank' style={{color:'rgb(128 134 146)'}} >Careers</a>
				</div>
		</>
	)

	const Legal=(
		<>
			<div className=" info_nm_ft_cls dispFlxContFooter">
					Legal
			</div>
			<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
			   <a target='_blank' href='https://onsurity.com/terms-conditions/' style={{color:'rgb(128 134 146)'}} >Terms & Conditions</a>
					<a title="Onsurity - Terms and Conditions" href="https://onsurity.com/terms-conditions/" alt="T&Cs" style={{color:'rgb(128 134 146)',fontSize: '0px'}} onClick={()=>props.setNavSelected("")}>Terms & Conditions</a>
			</div>
			<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
			   <a target='_blank' href='https://onsurity.com/privacy-policy/' style={{color:'rgb(128 134 146)'}} >Privacy Policy</a>
				<a title="Onsurity - Privacy Ploicy" href="https://www.onsurity.com/teamsure/privacy" alt="privacy policy" style={{color:'rgb(128 134 146)',fontSize: '0px'}} onClick={()=>props.setNavSelected("")}>Privacy Policy</a>
			</div>
		</>
	)

	const twitterClkFn = (e) => {
		let lobj = {};
		lobj.clicked = true;
		Clevertap("Twitter",lobj);
	}

	const fbClkFn = (e) => {
		let lobj = {};
		lobj.clicked = true;
		Clevertap("Facebook",lobj);
	}

	return(
		<div>
	<div className="footer-mg">
		<div className="dispFlxContFooter ft_algn_cntr">

			<div className="snd_cmp_name_cls">
				<div className=" cmp_nm_ft_cls dispFlxContFooter ">Onsurity Technologies Private Limited
					{/* <LazyImage src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1594121892/New-home-screen/Onsurity_fawma3.svg" /> */}
				</div>
				<div className=" ft_dtls_cnt_Cls dispFlxContFooter ">
				Corporate Address:<br/>
					05-102-A,
					<br/>
					WeWork Vaishnavi Signature, <br/>No. 78/9, Outer Ring Road, Bellandur Village, <br/>
					 Varthur Hobli, Bengaluru, Karnataka - 560103
				</div>
				<div className=" resp-right mr top-mar-ico ft_disp_flex rmvPad_ft">
					   <a href="https://www.facebook.com/onsurity/" onClick={(e)=>{fbClkFn(e)}} target="_blank" rel="noopener noreferrer" className=" ft_btns_rgt ftMar0px">
							{<LazyImage height="64px" width={'64px'} className="lazyload" src="https://res.cloudinary.com/onsurity/image/upload/v1594119907/New-home-screen/faceBkIcnFtr_jol7zd.svg" alt="social-icons" /> }
						</a>
						<a href="https://www.instagram.com/onsurity_/" target="_blank" rel="noopener noreferrer" className="mx-2 ft_btns_rgt">
							{ <LazyImage height="64px" width={'64px'} className="lazyload" src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1594119907/New-home-screen/InstaIcnFtr_dt2i5p.svg" alt="social-icons" /> }
						</a>
						{/* <a href="https://youtube.com/" target="_blank" className="mx-2">
							<LazyImage height="30px" src={require('../../assets/footer/youtube.png')} alt="social-icons" />
						</a> */}
						<a href="https://twitter.com/onsurity" onClick={(e)=>{twitterClkFn(e)}} target="_blank" rel="noopener noreferrer" className="mx-2 ft_btns_rgt">
							{ <LazyImage height="64px" width={'64px'} className="lazyload"
								src="https://res.cloudinary.com/onsurity/image/upload/v1594119907/New-home-screen/TwtrIcnFtr_l56qey.svg"
									alt="social-icons" />}
						</a>
						<a href="https://www.linkedin.com/company/onsurity/" target="_blank" rel="noopener noreferrer" className="mx-2 ft_btns_rgt">
							{ <LazyImage height="64px" width={'64px'} className="lazyload" src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1594119908/New-home-screen/LinkedInIcnFtr_plf3fs.svg" alt="social-icons" /> }
						</a>
				</div>
			</div>


			<div className="trd_info_div_cls footer_div_hide">
				{Information}
			</div>
			<div className="frth_lgl_div_cls footer_div_hide">
				 {Legal}
			</div>
			{/*<div className="fifh_news_div_cls">
				<div className=" cmp_nm_ft_cls dispFlxContFooter">
					Onsurity Newsletter
				</div>
				<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
					Terms & Conditions
				</div>
				<div className=" ft_dtls_cnt_Cls dispFlxContFooter">
					Privacy Policy
				</div>
</div>*/}
		</div>
		<div className="mobile_footer_flwex">
				<div className={!isMobile?"ml-4":""} style={{width:'50%'}}>
					{Information}
				</div>
				<div className="ml-4" style={{width:'50%'}}>
					{Legal}
				</div>
		</div>
		{/*
			urls.HOME == window.location.pathname && (
				<HomeFooterSeo />
			)
		*/}
		{/*
			urls.GROUP_HEALTH == window.location.pathname && (
				<GHISeo />
			)
		*/}
		</div>
		<div className="ft_bgclr_bl">
			  <div className=" c-tect ft_clr_blk">© 2022 Onsurity Technologies Private Limited </div>
		</div>
	</div>
	);

}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
	  ...user_state,
	  //ADD IF ITS FROM DIFFRENT ACTION FILE
  }, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FooterComponent))
