import React from "react";
import "./partnerCard.scss";

const PartnerCard = (props) => {
  return (
    <>
      {props?.data?.map((item, index) => {
        return (
          <div className="partner-card-div">
            <div className="partner-card-logo">
              <img alt="benefits_logo" src={item?.imgUrl} />
            </div>
            <div>{item?.content}</div>
          </div>
        );
      })}
    </>
  );
};
export default PartnerCard;
