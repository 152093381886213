import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/payment_options';
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';
import { provider } from '../../utils/Env';

//GET EMP
function* fetchPaymtOpt(action) {
    try{
        let optionUrl=pageURL.FETCH_PAYMENT_OPTIONS;
        const response = yield call (apiCall, "get", optionUrl);
        // 
        if(response.data.success===1){
         yield put({ type: actionTypes.PAYMENT_OPTION_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.PAYMENT_OPTION_FAIL, data:response.data});
        }

    }catch(e){
        if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.PAYMENT_OPTION_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.PAYMENT_OPTION_FAIL, data:g_Failed});
        }
    }

}
export function* paymentOptionWatcher() {
     yield takeLatest(actionTypes.PAYMENT_OPTION, fetchPaymtOpt)
}
