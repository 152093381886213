import React from "react";

// Styles
import "./BenefitsCard.scss";
import { useHistory } from "react-router-dom";

function BenefitsCard({ topHeader, cardPoints, priceContent, btnContentObj }) {
  // Button Click Handler
  const history = useHistory();
  const btnClickHandler = (url) => {
    history.push(url);
    // window.open(url, "_blank", "noreferrer");
  };

  return (
    <div className="benefits-card">
      <div className="benefits-card-wrapper">
        <div className="top-header-wrapper">
          <h3 className="top-header">
            <strong>{topHeader}</strong>
          </h3>
        </div>

        <div className="card-points">
          <ul className="card-points-list">
            {cardPoints.map((cardPoint, idx) => {
              return (
                <li key={idx}>
                  <span>{cardPoint}</span>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="price-block">
          <p dangerouslySetInnerHTML={{ __html: priceContent }}></p>
        </div>

        <div className="card-btn-wrapper">
          <button
            className="card-btn"
            onClick={() => btnClickHandler(btnContentObj.url)}
          >
            {btnContentObj.title}
          </button>
        </div>
      </div>
    </div>
  );
}

export default BenefitsCard;
