export const types={
    PAYMENT_OPTION:'PAYMENT/PAYMENT_OPTION',
    PAYMENT_OPTION_SUCCESS:'PAYMENT/PAYMENT_OPTION_SUCCESS',
    PAYMENT_OPTION_FAIL:'PAYMENT/PAYMENT_OPTION_FAIL'
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.PAYMENT_OPTION:
            return { ...state,  isLoading:true, data: null}

      case types.PAYMENT_OPTION_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.PAYMENT_OPTION_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

      default:
        return state
    }
  }

  export const actions={
      fetchPaymentOptiions:()=>({type:types.PAYMENT_OPTION})
  }
