import React from "react";

// Styles
import "./WhyOnsurityCard.scss";

function WhyOnsurityCard({ imgUrl, cardText }) {
  return (
    <div className="why-onsurity-card">
      <div className="why-onsurity-card-wrapper">
        <div className="card-img-wrapper">
          <img src={imgUrl} alt="Why Onsurity - Icon" />
        </div>

        <h3 className="card-text">{cardText}</h3>
      </div>
    </div>
  );
}

export default WhyOnsurityCard;
