export const types={
    FETCH_COMPANY:'GST/FETCH_COMPANY',
    FETCH_COMPANY_SUCCESS:'GST/FETCH_COMPANY_SUCCESS',
    FETCH_COMPANY_FAIL:'GST/FETCH_COMPANY_FAIL',
    FETCH_COMPANY_DETAILS:'GST/FETCH_COMPANY_DETAILS',
    SET_COMPANY_DETAILS:'GST/SET_COMPANY_DETAILS',
    SET_RESET_DATA:'GST/SET_RESET_DATA',
    SET_SEZ_DATA:"GST/SET_SEZ_DATA"
}

export const initialState = {
    iptTxt:'',
    data: null,
    isLoading: false,
    error: null,
    selectedCompany: null,
    selectSez:false
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.SET_RESET_DATA:
            return initialState;

      case types.FETCH_COMPANY:
            return { ...state, iptTxt:action.data, isLoading:true, data: null, selectedCompany:null}

      case types.FETCH_COMPANY_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.FETCH_COMPANY_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

            case types.SET_SEZ_DATA:
                  return { ...state, isLoading:false, error:true, selectSez:action.data}

      case types.FETCH_COMPANY_DETAILS:
            state.selectedCompany = action.data;
            return { ...state}

      case types.SET_COMPANY_DETAILS:
            state.selectedCompany = null;
            state.data = null;
            state.isLoading = false;
            state.error = null;
            return { ...state}
      
 
      default:
        return state
    }
  }

  export const actions={
      fetchCompany:(getCmpNm)=>({type:types.FETCH_COMPANY, data:getCmpNm}),
      selectedCompany:(lobj)=>({type:types.FETCH_COMPANY_DETAILS, data:lobj}),
      setInitValSearch:()=>({type:types.SET_COMPANY_DETAILS, data:null}),
      resetCompany:()=>({type:types.SET_RESET_DATA}),
      setSwitchSez:(data)=>({type:types.SET_SEZ_DATA, data}),
  }
