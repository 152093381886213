//############################################//
let env="preprod" //dev, qa, prod stage demo preprod
//############################################//

const baseUrl= env==="prod"?'https://gateway.onsurity.com':`https://gateway.${env}.onsurity.com`

const profileConfig = {
   [env]: {
    global: `${baseUrl}/globalbackend/api/v1`,
    employeesure:`${baseUrl}/employeesure/api/v1`
  }
};

export const DashboardUrl=profileConfig[env].dashboardUrl;

export const urlPrefixGlobal = (url) =>{
  return profileConfig[env].global + url;
}
export const urlPrefixEmpSure = (url) =>{
  return profileConfig[env].employeesure + url;
}

export const pageURL = {
  ENV : env,
  LOGIN_URL : urlPrefixGlobal("/users?country_id=~cId~&mobile_no=~mNum~"),
  VERIFY_LOGIN_OTP: urlPrefixGlobal("/users/verify/otp"),
  FETCH_GSTIN : urlPrefixEmpSure("/employer/gstin/~GSTIN~"),
  SEARCH_COMPANY: urlPrefixEmpSure("/employer/search-employer?text=~COMPANY_NAME~"),
  CHECKOUT_ORDER: urlPrefixEmpSure("/checkout/order/create"),
  GET_EMP: urlPrefixEmpSure("/getempdata"),
  FETCH_GSTIN: urlPrefixEmpSure("/employer/gstin/~gstin~"),
  FETCH_PLANS: urlPrefixEmpSure("/plan/get-plans"),
  CART_URL:urlPrefixEmpSure("/carts/update"),
  CART_URL_GET_DETAILS:urlPrefixEmpSure("/carts/get"),
  FAQ_URL:urlPrefixGlobal("/faq"),
  INVITE_APP_URL: urlPrefixGlobal("/applink?mobile_no=~mNum~"),
  FETCH_STATIC_PRICING: urlPrefixEmpSure("/plan/plan-pricing-details?group_id=~PLAN_IDS~&policy=~POLICY~"),
  FETCH_PAYMENT_OPTIONS: urlPrefixEmpSure("/checkout/payment-options"),
  SET_ORDER: urlPrefixEmpSure("/checkout/order/create"),
  VERIFY_ORDER: urlPrefixEmpSure("/checkout/order/verify"),
  LOGOUT_SESSION:urlPrefixGlobal("/logout"),
  
  WHATSAPP_PATCH_URL:urlPrefixGlobal("/users/whatsapp"),
  COVERAGES_URL:urlPrefixEmpSure("/plan/coverages"),
  TESTIMONAIL_URL: urlPrefixEmpSure("/web-contents"),
  GHI_SUMMARY: urlPrefixEmpSure("/plan/plan-for-relation"),
  // LOGIN_OUT_URl : urlPrefixGlobal("/logout"),
}
