export const types={
    MANUPLATE_STACK:'STACK/MANUPLATE_STACK',
    ADD_LOADED:'STACK/ADD_LOADED',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    VERIFY_LOGIN_REQ: 'STACK/VERIFY_LOGIN_REQ',
    VERIFY_LOGIN_SUCCESS: 'STACK/VERIFY_LOGIN_SUCCESS',
    VERIFY_LOGIN_FAIL: 'STACK/VERIFY_LOGIN_FAIL',
    LOGOUT_REQUEST: 'STACK/LOGOUT_REQUEST',
    LOGOUT_REQUEST_SUCCESS:'STACK/LOGOUT_REQUEST_SUCCESS',
    LOGOUT_REQUEST_FAIL:'STACK/LOGOUT_REQUEST_FAIL',
    RESET_AUTH_OBJ:'STACK/RESET_AUTH_OBJ',
    PATCH_WHATSAPP:'STACK/PATCH_WHATSAPP',
    PATCH_WHATSAPP_SUCCESS:'STACK/PATCH_WHATSAPP_SUCCESS',
    PATCH_WHATSAPP_FAIL:'STACK/PATCH_WHATSAPP_FAIL'
}

export const initialState = {
    stack:[0],
    nav_loaded:'0%',
    isLoading: false,
    authObj:{
      authStatus:false,
      whatsappStatus:false
    },
    logOutData:null,
    whatsappData:null
  }

const ReverseLoader=(value)=>{
    switch (value) {
        case 0:
            return '0%'
        case 1:
            return '25%'
        case 2:
            return '50%'
        case 3:
            return '75%'
        default:
            return '0%'
    }
}

export default (state = initialState, action) => {
    switch (action.type) {

      case types.ADD_LOADED:
            state.nav_loaded=action.value
            return { ...state}

      case types.LOGIN_REQUEST :
        state.isLoading = true;
        state.number=action.data.num;
        state.authObj = {
          authStatus:false
        };
        return {...state};
      case types.LOGIN_SUCCESS :
        state.isLoading = false;
        state.authObj = action.authObj;
        return {...state}
      case types.LOGIN_FAILURE :
        state.isLoading = false;
        state.authObj = action.authObj;
        return {...state}


      case types.VERIFY_LOGIN_REQ :
        state.isLoading = true;
        state.authObj.authStatus = false;
        state.authObj.sendOtp = "Done";
        return {...state}
      case types.VERIFY_LOGIN_SUCCESS :
        state.isLoading = false;
        state.authObj = action.authObj;
        return {...state}
      case types.VERIFY_LOGIN_FAIL :
        state.isLoading = false;
        state.authObj = action.authObj;
        return {...state}

      case types.LOGOUT_REQUEST:
        state.isLoading = true;
        return {...state}
      case types.LOGOUT_REQUEST_SUCCESS:
        state.isLoading = false;
        state.authObj.authStatus = false;
        state.logOutData = action.data;
        return {...state}
      case types.LOGOUT_REQUEST_FAIL:
        state.isLoading = false;
        state.logOutData = action.data;
        return {...state}
      case types.RESET_AUTH_OBJ:
        return initialState;

        case types.PATCH_WHATSAPP :
        state.isLoading = true;
        return {...state}
      case types.PATCH_WHATSAPP_SUCCESS :
        state.isLoading = false;
        state.whatsappData = action.data;
        return {...state}
      case types.PATCH_WHATSAPP_FAIL :
        state.isLoading = false;
        state.whatsappData = action.data;
        return {...state}
      default:
        return state
    }
  }

export const actions={
    menuplateStack:(operation)=>({type:types.MANUPLATE_STACK, operation}),
    setLoaded:(value)=>({type:types.ADD_LOADED, value}),
    loginRequest: (loginReqObj) => ({ type: types.LOGIN_REQUEST, data: loginReqObj}),
    verifyLoginOtp: (verifyReqObj) => ({type: types.VERIFY_LOGIN_REQ, data: verifyReqObj}),
    logout:()=>({type:types.LOGOUT_REQUEST}),
    resetAuthObj:()=>({type:types.RESET_AUTH_OBJ}),
    updateWhatsapp:(bool)=>({type:types.PATCH_WHATSAPP, bool}),
}
