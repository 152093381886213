import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// import CheckoutSkeleton from "../layouts/CheckOut/CheckoutSkeleton";
// import PlanSkeleton from "../layouts/PlanPurchase/PlanSkeleton";
// import PurchaseSkeleton from "../layouts/GetStarted/Skeleton";
// import PhoneNumber from "../layouts/GetStarted/Components/Verification/PhoneNumber";
// import Otp from "../layouts/GetStarted/Components/Verification/Otp";
// import Gstin from "../layouts/GetStarted/Components/Gstin/Gstin";
// import CompanyDetails from "../layouts/GetStarted/Components/CompanyDetails/CompanyDetails";
// import PaymentSkeleton from "../layouts/Payment/PaymentSkeleton";
// import SummarySkeleton from "../layouts/SummaryMob/SummarySkeleton";
// import DetailsSkeleton from "../layouts/Details/DetailsSkeleton";
// import ContactUsSkeleton from "../layouts/ContactUs/ContactUsSkeleton";
import { connect } from "react-redux";
// import NotFound from "../Input-controls/404/NotFound";
import { heightWidthCalc } from '../utils/Api.js';
// import Faq from "../layouts/Faq/Faq";
import Privacy from "../layouts/Privacy-Policy/Privacy";
import ScrollToTop from '../utils/ScrollToTop'
// import AboutUsSkeleton from "../layouts/About/AboutUsSkeleton";
// import Success from "../layouts/PaymentSuccess/Success";
// import Failed from "../layouts/PaymentFail/Failed";
// import Pending from "../layouts/Payment-Pending/Pending";
// import Testimonial from "../layouts/Testimonial/Testimonial";
// import CareerSkeleton from '../layouts/Career/Job-Listing/CareerSkeleton';

// import Home from '../layouts/Home/Home';
// import HomeLetsVenture from '../layouts/HomeLetsVenture/Home';
// import LGHome from '../layouts/HomeLG/Home'
// import AddPeople from '../layouts/AddPeople/AddPeople_Skelton'
import { OnsurityUrls } from './urls'
// import Coverage from "../layouts/Coverage/Coverage";
// import FitnessRewards_Details from "../layouts/Benefits-Details/FitnessRewards_Details";
// import DiscountMedicine_Details from "../layouts/Benefits-Details/DiscountMedicine_Details";
// import HealthCheckup_Details from "../layouts/Benefits-Details/HealthCheckup_Details";
// import DoctorConsult_Details from "../layouts/Benefits-Details/DoctorConsult_Details";
// import GroupHealthins_Details from "../layouts/Benefits-Details/GroupHealthins_Details";
// import GoodDoctor_Details from "../layouts/Benefits-Details/GoodDoctor_Details";
// import GroupTerm_life_Details from "../layouts/Benefits-Details/GroupTerm_life_Details";
// import MarketingPage from "../layouts/Marketing/MarketingPage";
// import Oops from "../layouts/Oops-NotFound/Oops";
import AppRedirect from "../layouts/AppRedirect/AppRedirect";
import CustomRoute from "./CustomRoute";
import Blog from "../components/Common/Blog/Blog";
// import HomePage from "../layouts/MarketingLandingPage/HomePage";
// import Redirect1mg from "../layouts/Redirect-1mg/Redirect1mg";
// import CompanyMain from "../layouts/GetStarted/Components/Gstin/Main";
// import ProxyRequest from "../layouts/GetStarted/ProxyRequest/ProxyRequest";
import TermsOfUse from "../layouts/Terms-0f-Use/TermsOfUse";
import { GTMWrapper } from "./GTMWrapper.js";

// Partner Page
import PartnerPage from '../layouts/Partner-Page/index'


const Home = lazy(() => import('../layouts/Home/Home'));
const HomeLetsVenture = lazy(() => import('../layouts/HomeLetsVenture/Home'));
const FitnessRewards_Details = lazy(() => import("../layouts/Benefits-Details/FitnessRewards_Details"));
const DiscountMedicine_Details = lazy(() => import("../layouts/Benefits-Details/DiscountMedicine_Details"));
const HealthCheckup_Details = lazy(() => import("../layouts/Benefits-Details/HealthCheckup_Details"));
const DoctorConsult_Details = lazy(() => import("../layouts/Benefits-Details/DoctorConsult_Details"));
const GroupHealthins_Details = lazy(() => import("../layouts/Benefits-Details/GroupHealthins_Details"));
const GoodDoctor_Details = lazy(() => import("../layouts/Benefits-Details/GoodDoctor_Details"));
const GroupTerm_life_Details = lazy(() => import("../layouts/Benefits-Details/GroupTerm_life_Details"));
const Oops = lazy(() => import("../layouts/Oops-NotFound/Oops"));
const CompanyDetails = lazy(() => import("../layouts/GetStarted/Components/CompanyDetails/CompanyDetails"));
const Otp = lazy(() => import("../layouts/GetStarted/Components/Verification/Otp"));
const PhoneNumber = lazy(() => import("../layouts/GetStarted/Components/Verification/PhoneNumber"));
const MarketingPage = lazy(() => import("../layouts/Marketing/MarketingPage"));
const HomePage = lazy(() => import("../layouts/MarketingLandingPage/HomePage"));
const Redirect1mg = lazy(() => import("../layouts/Redirect-1mg/Redirect1mg"));
const CompanyMain = lazy(() => import("../layouts/GetStarted/Components/Gstin/Main"));
// const Privacy = lazy(() => import('../layouts/Privacy-Policy/Privacy'));
const Testimonial = lazy(() => import("../layouts/Testimonial/Testimonial"));
const CheckoutSkeleton = lazy(() => import("../layouts/CheckOut/CheckoutSkeleton"));
const PlanSkeleton = lazy(() => import("../layouts/PlanPurchase/PlanSkeleton"));
// const PurchaseSkeleton = lazy(() => import( "../layouts/GetStarted/Skeleton"));
const PaymentSkeleton = lazy(() => import("../layouts/Payment/PaymentSkeleton"));
const SummarySkeleton = lazy(() => import("../layouts/SummaryMob/SummarySkeleton"));
const ContactUsSkeleton = lazy(() => import("../layouts/ContactUs/ContactUsSkeleton"));
// const ScrollToTop = lazy(() => import('../utils/ScrollToTop'))
const AboutUsSkeleton = lazy(() => import("../layouts/About/AboutUsSkeleton"))
const Success = lazy(() => import("../layouts/PaymentSuccess/Success"))
const Failed = lazy(() => import("../layouts/PaymentFail/Failed"))
const Pending = lazy(() => import("../layouts/Payment-Pending/Pending"))
const LGHome = lazy(() => import('../layouts/HomeLG/Home'))
const AddPeople = lazy(() => import('../layouts/AddPeople/AddPeople_Skelton'))


//this export is used everywhere dont delete it !!!
export const urls = OnsurityUrls;

const Routes = (props) => {


    const GetHomeUrl = () => {
        if ("LG" in localStorage && localStorage.getItem("LG") == 1) {
            return <Route exact path={urls.HOME} component={LGHome} />
        } else {
            return <CustomRoute
                exact
                path={urls.HOME}
                title="Onsurity - Affordable Employee Healthcare Benefits for Companies"
                description="Onsurity is leading employee healthcare platform for small businesses which includes group health insurance, discounted telemedicine, teleconsultation etc.."
                keywords="Affordable employee Healthcare, employee health insurance, employee medical benefits, healthcare and employee benefits, employee health benefits"
                component={Home} />
        }
    }

    return (
        <Router>
            <GTMWrapper>
            <ScrollToTop />
            <Switch>
           
            <CustomRoute
                    path={urls.Terms_USE}
                    title="Terms of use - Onsurity"
                    description="View all the terms of use when you sign up for employee healthcare benefits from Onsurity"
                    keywords="Onsurity"
                    component={TermsOfUse}
                />
                <Route
                    path={urls.TERMS_OF_USE_MOB}
                    component={TermsOfUse} 
                    />
                    
                <Route
                    path={urls.PARTNER_URL}
                    component={PartnerPage}
                />
                
                <Suspense fallback={<div></div>}>

                    {GetHomeUrl()}

                    <CustomRoute
                        path={urls.TESTIMONIAL}
                        title="Onsurity Employee Healthcare Memberships Reviews | Partners Testimonials"
                        description="Here are the Onsurity customer reviews that best explains the value we have created in the employee helathcare landscape."
                        keywords="Onsurity"
                        component={Testimonial}
                    />

                    <CustomRoute
                        path={urls.CAMPARISION}
                        title="Onsurity: The Best Choice for all your Employee Healthcare Needs"
                        description="Onsurity versus market alternatives. Here's what sets us apart from standalone group health insurance firms."
                        keywords="Onsurity"
                        component={HomePage}
                    />

                    <CustomRoute
                        path={urls.SELECT_PLAN}
                        title="Select your healthcare membership plan"
                        description="Customise your Onsurity wellness membership plans to suit your team's needs, one of the many reasons our business health plans are the best employee healthcare option"
                        keywords="Onsurity"
                        component={PlanSkeleton}
                    />
                    <CustomRoute
                        path={urls.ADD_PEOPLE}
                        title="View your business health plans cost"
                        description="View the cost of your Onsurity wellness membership for all your team members"
                        keywords="Onsurity"
                        component={AddPeople}
                    />
                    <CustomRoute
                        path={urls.CHECKOUT}
                        title="Verify your Onsurity membership cost"
                        description="If you are satisfied with your employee healthcare plan checkout and proceed to payment."
                        keywords="Onsurity"
                        component={CheckoutSkeleton}
                    />
                    <CustomRoute
                        path={urls.PAYMENT}
                        title="Pay for your Onsurity healthcare membership"
                        description="Pay for your employee healthcare benefits monthly or yearly using our secure gateway."
                        keywords="Onsurity"
                        component={PaymentSkeleton}
                    />
                    <CustomRoute
                        path={urls.SUMMARY_MOB}
                        title="Summary of your Onsurity healthcare membership"
                        description="This page is a quick summary of your Onsurity healthcare membership so you know all you and your team get."
                        keywords="Onsurity"
                        component={SummarySkeleton}
                    />
                    {/* <CustomRoute
                path={urls.COVERAGES}
                title="Additional coverage for your employee healthcare plan"
                description="View a list of all additional coverages you can get for your group health insurance under Onsurity's healthcare benefits."
                keywords="Onsurity"
                component={Coverage}
            /> */}
                    <Route path={urls.FAQ_OLD} component={() => window.location.href = "https://onsurity.com/faq/"} />
                    <Route path={urls.BLOG_OLD} component={() => window.location.href = "https://blog.onsurity.com/"} />

                    <Route path={urls.CONTACT_US_APP}><Redirect to={urls.CONTACT_US_NEW} /></Route>
                    <Route path={urls.CONTACT_US}><Redirect to={urls.CONTACT_US_NEW} /></Route>

                    <CustomRoute
                        path={urls.CONTACT_US_NEW}
                        title="Contact us for Affordable Employee Healthcare Membership "
                        description="Contact Onsurity for queries about TeamSure, customer support to to partner with us"
                        keywords="Onsurity"
                        component={ContactUsSkeleton}
                    />

                    <Route path={urls.PRIVACY_POLICY_DUP}><Redirect to={urls.PRIVACY_POLICY} /></Route>

                    <CustomRoute
                        path={urls.PRIVACY_POLICY}
                        title="Privacy policy - Onsurity"
                        description="View the privacy policy for your healthcare membership under Onsurity"
                        keywords="Onsurity"
                        component={Privacy}
                    />

                    <Route
                        path={urls.PRIVACY_POLICY_MOB}
                        component={Privacy} />

                    <Route path={urls.Terms_USE_DUP}><Redirect to={urls.Terms_USE} /></Route>


                    <Route
                        path={urls.BLOG_OLD_OLD}
                        component={Blog} />


                    <Route
                        path={urls.BLOG_REDIRECT}
                        component={Blog} />

                    <CustomRoute
                        path={urls.PAYMENT_VERIFICATION}
                        title="Verify your payment to Onsurity"
                        description="Use the gateway to verify your payment to access employee healthcare benefits from Onsurity"
                        keywords="Onsurity"
                        component={Success}
                    />
                    <Route path={urls.ABOUT_US_OLD}><Redirect to={urls.ABOUT_US} /></Route>
                    <CustomRoute
                        path={urls.ABOUT_US}
                        title="Learn more about us - Onsurity"
                        description="Know about Onsurity, what we do, our founders and our vision for employee healthcare plans"
                        keywords="Onsurity"
                        component={AboutUsSkeleton}
                    />
                    <Route path={urls.ABOUT_US_MOBILE} component={AboutUsSkeleton} />

                    <CustomRoute
                        path={urls.PAYMENT_FAIL}
                        title="Payment failed - Onsurity"
                        description="Please try again. Your payment for your healthcare membership with Onsurity has failed"
                        keywords="Onsurity"
                        component={Failed}
                    />
                    <CustomRoute
                        path={urls.PAYMENT_PENDING}
                        title="Payment Processing - Onsurity"
                        description="Your payment towards your healthcare membership is processing please wait for confirmation from Onsurity"
                        keywords="Onsurity"
                        component={Pending}
                    />

                    <CustomRoute
                        path={urls.OOPS_NOTFOUND}
                        title="Onsurity webpage not found"
                        description="Sorry but the Onsurity webpage does not exist. Please visit our homepage for information on employee healthcare benefits"
                        keywords="Onsurity"
                        component={Oops}
                    />

                    <Route path={urls.FITNESS_TRACKING_OLD}><Redirect to={urls.FITNESS_TRACKING_NEW} /></Route>
                    <Route path={urls.FITNESS_TRACKING}><Redirect to={urls.FITNESS_TRACKING_NEW} /></Route>
                    <CustomRoute
                        path={urls.FITNESS_TRACKING_NEW}
                        title="Fitness Tracking | Onsurity"
                        description="Here are details on Fitness Tracking as part of your employee healthcare benefits under Onsurity"
                        keywords="Fitness Tracking"
                        component={FitnessRewards_Details} />

                    <Route path={urls.DISCOUNT_MEDICINE}><Redirect to={urls.DISCOUNT_MEDICINE_NEW} /></Route>
                    <CustomRoute
                        path={urls.DISCOUNT_MEDICINE_NEW}
                        title="Discounted Medicine | Order Medicine Online | Onsurity"
                        description="Here are details on discounted medication as part of your employee healthcare benefits under Onsurity"
                        keywords="Discounted medicine"
                        component={DiscountMedicine_Details}
                    />
                    <Route path={urls.DOCTOR_CONUSLTATION}><Redirect to={urls.DOCTOR_CONUSLTATION_NEW} /></Route>
                    <CustomRoute
                        path={urls.DOCTOR_CONUSLTATION_NEW}
                        title="Doctor Teleconsultation | Telemedicine | Onsurity"
                        description="Here are details on doctor teleconsultations as part of your employee healthcare benefits under Onsurity"
                        keywords="doctor teleconsultation"
                        component={DoctorConsult_Details}
                    />
                    <Route path={urls.HEALTH_CHECKUP}><Redirect to={urls.HEALTH_CHECKUP_NEW} /></Route>
                    <CustomRoute
                        path={urls.HEALTH_CHECKUP_NEW}
                        title="Discounted Health Checkups | Medical Checkups | Onsurity"
                        description="Here are details on health checkups as part of your employee healthcare benefits under Onsurity"
                        keywords="Discounted Healthcheckups"
                        component={HealthCheckup_Details}
                    />
                    <Route path={urls.GROUP_HEALTH_OLD}><Redirect to={urls.GROUP_HEALTH_NEW} /></Route>
                    <Route path={urls.GROUP_HEALTH}><Redirect to={urls.GROUP_HEALTH_NEW} /></Route>
                    <CustomRoute
                        path={urls.GROUP_HEALTH_NEW}
                        title="Group Health Insurance|Group Mediclaim Insurance Plans"
                        description="Here are details on group health insurance as part of your employee healthcare benefits under Onsurity"
                        keywords="Group Health Insurance"
                        component={GroupHealthins_Details}
                    />
                    <Route path={urls.GOOD_DOCTOR}><Redirect to={urls.GOOD_DOCTOR_NEW} /></Route>

                    <CustomRoute
                        path={urls.GOOD_DOCTOR_NEW}
                        title="Assistance During Hospitalization & Claims|Good Doctors Team"
                        description="Learn about the Good Doctor Team you can access as part of your employee healthcare benefits under Onsurity"
                        keywords="Good Doctor Team"
                        component={GoodDoctor_Details}
                    />

                    <Route path={urls.TERM_LIFE}><Redirect to={urls.TERM_LIFE_NEW} /></Route>
                    <CustomRoute
                        path={urls.TERM_LIFE_NEW}
                        title="Group Term Life Insurance Plans - Onsurity"
                        description="Learn about the group term life insurance you can access as part of your healthcare membership with Onsurity"
                        keywords="Group term life Insurance"
                        component={GroupTerm_life_Details}
                    />
                    <Route path={urls.PRODUCT_PAGE}><Redirect to={urls.PRODUCT_PAGE_NEW} /></Route>
                    <CustomRoute
                        path={urls.PRODUCT_PAGE_NEW}
                        title="Affordable Healthcare For Employees With Group Health Insurance"
                        description="Undestand the costs and employee healthcare benefits of TeamSure by Onsurity"
                        keywords="Onsurity"
                        component={MarketingPage}
                    />


                    <Route
                        path={urls.APP}
                        component={AppRedirect} />

                    <Route path={urls.LETS_VENTURE}><Redirect to={urls.LETS_VENTURE_NEW} /></Route>
                    <Suspense fallback={<div></div>}>
                        <CustomRoute
                            path={urls.LETS_VENTURE_NEW}
                            title="Onsurity’s Employee Healthcare Benefits for Sequoia Portfolio Companies"
                            description="Best employee healthcare platform for Sequoia startups which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                            keywords="Onsurity"
                            component={HomeLetsVenture} />
                    </Suspense>

                    <Route path={urls.MATRIX_PARTNER}><Redirect to={urls.MATRIX_PARTNER_NEW} /></Route>
                    <CustomRoute
                        path={urls.MATRIX_PARTNER_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Sequoia Portfolio Companies"
                        description="Best employee healthcare platform for Sequoia startups which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more."
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.LIGHT_SPEED_VENTURE}><Redirect to={urls.LIGHT_SPEED_VENTURE_NEW} /></Route>
                    <CustomRoute
                        path={urls.LIGHT_SPEED_VENTURE_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Light Speed Venture Companies"
                        description="Best employee healthcare platform for Light Speed Venture partners which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.CHIRATE_VENTURE}><Redirect to={urls.CHIRATE_VENTURE_NEW} /></Route>
                    <CustomRoute
                        path={urls.CHIRATE_VENTURE_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Chiratae Venture Portfolio Companies"
                        description="Best employee healthcare platform for Chiratae Venture partners which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.NEXUS_VENTURE}><Redirect to={urls.NEXUS_VENTURE_NEW} /></Route>
                    <CustomRoute
                        path={urls.NEXUS_VENTURE_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Nexus Venture Partner Portfolio Companies"
                        description="Best employee healthcare platform for Nexus venture partners which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.ANGELIST}><Redirect to={urls.ANGELIST_NEW} /></Route>
                    <CustomRoute
                        path={urls.ANGELIST_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for AngelList Startup Companies"
                        description="Best employee healthcare platform for AngelList Startup Companies which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.FLOURISH_VENTURE}><Redirect to={urls.FLOURISH_VENTURE_NEW} /></Route>
                    <CustomRoute
                        path={urls.FLOURISH_VENTURE_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Flourish Venture Portfolio Companies"
                        description="Best employee healthcare platform for Flourish venture partners which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.ACCION}><Redirect to={urls.ACCION_NEW} /></Route>
                    <CustomRoute
                        path={urls.ACCION_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Accion Portfolio Companies"
                        description="Best employee healthcare platform for Accion portfolio companies which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.OMIDYAR_NETWORK}><Redirect to={urls.OMIDYAR_NETWORK_NEW} /></Route>
                    <CustomRoute
                        path={urls.OMIDYAR_NETWORK_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Omidyar Network Companies"
                        description="Best employee healthcare platform for Omidyar Network Companies which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.MAYFIELD_VENTURE}><Redirect to={urls.MAYFIELD_VENTURE_NEW} /></Route>
                    <CustomRoute
                        path={urls.MAYFIELD_VENTURE_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Mayfield Venture Companies"
                        description="Best employee healthcare platform for Mayfield Venture Companies which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.INDIAN_NETWORK}><Redirect to={urls.INDIAN_NETWORK_NEW} /></Route>
                    <CustomRoute
                        path={urls.INDIAN_NETWORK_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Indian Angel Network Companies"
                        description="Best employee healthcare platform for Indian Angel Network Companies which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />

                    <Route path={urls.MUMBAI_NETWORK}><Redirect to={urls.MUMBAI_NETWORK_NEW} /></Route>
                    <CustomRoute
                        path={urls.MUMBAI_NETWORK_NEW}
                        title="Onsurity’s Employee Healthcare Benefits for Mumbai Angel Network Companies"
                        description="Best employee healthcare platform for Mumbai Angel Network Companies which includes group health insurance, discounted medicines, health check-ups, teleconsultation and more"
                        keywords="Onsurity"
                        component={HomeLetsVenture} />
                    <Route
                        path={urls.KAE_CAPITAL}
                        component={HomeLetsVenture} />
                    <Route
                        path={urls.STARTUP}
                        component={HomeLetsVenture} />

                    <Route path={urls.TECHTHIRTY}><Redirect to={urls.TECHTHIRTY_NEW} /></Route>
                    <CustomRoute
                        path={urls.TECHTHIRTY_NEW}
                        title="Employee Healthcare with Group Health Insurance Benefits for Techsparks30"
                        description="Best employee healthcare platform for small businesses which includes group health insurance, discounted telemedicine, health check-ups, teleconsultation, fitness tracking and more"
                        keywords="techsparks30 partnership"
                        component={HomeLetsVenture} />

                    <Route path={urls.REDBUS}><Redirect to={urls.REDBUS_NEW} /></Route>
                    <CustomRoute
                        path={urls.REDBUS_NEW}
                        title="Employee Healthcare with Group Health Insurance Benefits for Redbus"
                        description="Best employee healthcare platform for employees which includes group health insurance, discounted telemedicine, health check-ups, teleconsultation, fitness tracking and more"
                        keywords="redbus partnership"
                        component={HomeLetsVenture} />

                    <Route path={urls.SEPEQTA}><Redirect to={urls.SEPEQTA_NEW} /></Route>
                    <CustomRoute
                        path={urls.SEPEQTA_NEW}
                        title="Employee Healthcare with Group Health Insurance Benefits for Speqta"
                        description="Best employee healthcare platform for small businesses which includes group health insurance, discounted telemedicine, health check-ups, teleconsultation, fitness tracking and more"
                        keywords="speqta partnership"
                        component={HomeLetsVenture} />

                    <Route path={urls.GIN_SERVE}><Redirect to={urls.GIN_SERVE_NEW} /></Route>
                    <CustomRoute
                        path={urls.GIN_SERVE_NEW}
                        title="Employee Healthcare with Group Health Insurance Benefits for Ginserv"
                        description="Best employee healthcare platform for small businesses which includes group health insurance, discounted telemedicine, health check-ups, teleconsultation, fitness tracking and more"
                        keywords="ginserv partnership"
                        component={HomeLetsVenture} />

                    <Route path={urls.SPRING_WORKS}><Redirect to={urls.SPRING_WORKS_NEW} /></Route>
                    <CustomRoute
                        path={urls.SPRING_WORKS_NEW}
                        title="Affordable Employee healthcare with Group Medical Insurance for Springworks clients"
                        description="One-stop for medicine orders, diagnostics, teleconsultation, group insurance benefits, fitness and more"
                        keywords="springworks partnership"
                        component={HomeLetsVenture} />

                    <Route path={urls.LEAD_ANGELS}><Redirect to={urls.LEAD_ANGELS_NEW} /></Route>
                    <CustomRoute
                        path={urls.LEAD_ANGELS_NEW}
                        title="Employee Healthcare with Group Health Insurance Benefits for Leadangels"
                        description="One-stop for medicine orders, diagnostics, teleconsultation, group insurance benefits, fitness and more"
                        keywords="Leadangels partnership"
                        component={HomeLetsVenture} />

                    <Route path={urls.CLUB_INDIA}><Redirect to={urls.CLUB_INDIA_NEW} /></Route>
                    <CustomRoute
                        path={urls.CLUB_INDIA_NEW}
                        title="Affordable Employee Healthcare Plans For Your Companies"
                        description="Employee Healthcare with Group Health Insurance Benefits for Caclubindia"
                        keywords="Caclubindia partnership"
                        component={HomeLetsVenture} />

                    <Route path={urls.QUIKR}><Redirect to={urls.QUIKR_NEW} /></Route>
                    <CustomRoute
                        path={urls.QUIKR_NEW}
                        title="Employee Healthcare with Group Health Insurance Benefits for Quikr"
                        description="Affordable Employee Healthcare with Group Health Insurance Benefits for Quikr"
                        keywords="Quikr Partnership"
                        component={HomeLetsVenture} />

                    <CustomRoute
                        path={urls.REDIRECT_1MG}
                        title="Affordable Employee healthcare with Group Medical Insurance for Springworks clients"
                        description="One-stop for medicine orders, diagnostics, teleconsultation, group insurance benefits, fitness and more"
                        keywords="springworks partnership"
                        component={Redirect1mg} />

                    {
                        heightWidthCalc().width < 800 ? (
                            <Route path={urls.SUMMARY_MOB} component={SummarySkeleton} />
                        ) : null
                    }
              
              {/* </Suspense> */}
              {/* <PurchaseSkeleton HandleClick={props.HandleClick}> */}
               
               <Route path={urls.VERIFY_PHONE_OLD}><Redirect to={urls.VERIFY_PHONE} /></Route>
               <CustomRoute
                   path={urls.VERIFY_PHONE}
                   title="Enter phone number to access Onsurity"
                   description="Use your phone number to access and manage your employee healthcare benefits from Onsurity"
                   keywords="Onsurity"
                   component={PhoneNumber}
               />
               <CustomRoute
                   path={urls.VERIFY_OTP}
                   title="Enter OTP to verify with Onsurity"
                   description="The OTP will allow you to verify your Onsurity accout to acess your healthcare benefits dashboard"
                   keywords="Onsurity"
                   component={Otp}
               />
               <CustomRoute
                   path={urls.GSTIN_DETAILS}
                   title="Share your GST details with Onsurity"
                   description="Onsurity asks for GSTIN to tax your healthcare membership as per the law. This is required to access the best employee healthcare platform"
                   keywords="Onsurity"
                   component={CompanyMain}
               />
               <CustomRoute
                   path={urls.COMPANY_DETAILS}
                   title="Provide Onsurity with company details"
                   description="Onsurity needs your company details to provide the right wellness membership plans for you, since we provide a healthcare membership designed for your team. That's why we are the best employee healthcare platform."
                   keywords="Onsurity"
                   component={CompanyDetails}
               />
           {/* </PurchaseSkeleton> */}
                
                    </Suspense>


                </Switch>
            </GTMWrapper>
        </Router>
    )
}

const mapStateToProps = (state) => ({
    auth: state.navigationStack.authObj
})

export default connect(mapStateToProps, null)(Routes)
