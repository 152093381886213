import React from 'react';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const absoluteUrl = path => `https://onsurity.com${path}`;

const getMetaTags = ({
    title, description, url, contentType, published, updated, category, tags, keywords, image
}) => {
    const metaTags = [
        { itemprop: 'name', content: title },
        { itemprop: 'description', content: description },
        { name: 'description', content: description },
        { name: 'keywords', content: keywords },
        { property: 'og:image', content: image },
        { property: 'og:title', content: title },
        { property: 'og:type', content: contentType },
        { property: 'og:url', content: url },
        { property: 'og:description', content: description },
        { property: 'og:site_name', content: 'Onsurity Services Private Limited' },
        { property: 'og:locale', content: 'en_EN' },
        { property: 'fb:app_id', content: '458778398239479' },
    ];

    if (published) metaTags.push({ name: 'article:published_time', content: published });
    if (updated) metaTags.push({ name: 'article:modified_time', content: updated });
    if (category) metaTags.push({ name: 'article:section', content: category });
    if (tags) metaTags.push({ name: 'article:tag', content: tags });

    return metaTags;
};

const getHtmlAttributes = ({
    schema
}) => {
    let result = {
        lang: 'en',
    };
    if (schema) {
        result = {
            ...result,
            itemscope: undefined,
            itemtype: `http://schema.org/${schema}`,
        }
    }
    return result;
}


const Seo = ({
    schema, title, description, contentType, published, updated, category, tags, keywords, url, image
}) => (
        
        <Helmet
            htmlAttributes={getHtmlAttributes({
                schema,
            })}
            title={title}
            link={[
                { rel: 'canonical', href: absoluteUrl(url) },
            ]}
            meta={getMetaTags({
                title,
                description,
                keywords,
                contentType,
                url : absoluteUrl(url),
                published,
                updated,
                category,
                tags,
                image,
            })}
            script={[{
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                    "@context" : "http://schema.org",
                    "@type": "Organization",
                    "name": "Onsurity",
                    "image" : "https://healthsure.s3.ap-south-1.amazonaws.com/product-home.svg",
                    "title" : "Onsurity - Affordable Employee Healthcare Benefits for Companies",                                                            
                    "description" : "An Employee healthcare plan with monthly billing which includes group health insurance, discounted health check ups, discounted medicines, phone or video doctor consultation and many other benefits.",
                    "url" : "https://www.onsurity.com/",
                    "brand" : {
                    "@type" : "Brand",
                    "name" : "Onsurity",
                    "logo" : "https://healthsure.s3.ap-south-1.amazonaws.com/onsurity-logo.svg"
                     },
                    "potentialAction": {
                    "@type": "SearchAction",
                    "target": "https://www.onsurity.com/search?q={search_term_string}",
                    "query-input": "required name=search_term_string"
                     },
                    "sameAs": [
                    "https://www.facebook.com/onsurity/",
                    "https://twitter.com/onsurity",
                    "https://www.instagram.com/onsurity_/",
                    "https://www.linkedin.com/company/onsurity"
                     ]
                })
              }]}
        />
    );

export default Seo;
