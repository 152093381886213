import React from 'react'
import './Loader.scss'

export default function Loader(props) {
    return (
   <div className={`${props.FullScreen && "lader-parent"}`}>
        <div className="loader-container">
            <div style={{textAlign: 'center'}}>
                <img className="loaderGlobalCenter" height="90px" src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1596526408/New-home-screen/boom-04_1_psgvwn.gif" alt="loading" />
            </div>
        </div>
   </div>
    )
}
