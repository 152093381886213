export const types={
    VERIFY_ORDER:'ORDER/VERIFY_ORDER',
    VERIFY_ORDER_SUCCESS:'ORDER/VERIFY_ORDER_SUCCESS',
    VERIFY_ORDER_FAIL:'ORDER/VERIFY_ORDER_FAIL'
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.VERIFY_ORDER:
            return { ...state,  isLoading:true, data: null}

      case types.VERIFY_ORDER_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.VERIFY_ORDER_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

      default:
        return state
    }
  }

  export const actions={
      verifyOrderFn:(obj)=>({type:types.VERIFY_ORDER, obj})
  }
