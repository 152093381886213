export const types={
    GET_COVERAGES:'CART/GET_COVERAGES',
    GET_COVERAGES_SUCCESS:'CART/GET_COVERAGES_SUCCESS',
    GET_COVERAGES_FAIL:'CART/GET_COVERAGES_FAIL'
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.GET_COVERAGES:
            return { ...state, isLoading:true, data: null}

      case types.GET_COVERAGES_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.GET_COVERAGES_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

      default:
        return state
    }
  }

  export const actions={
      getCoverages:(payload)=>({type:types.GET_COVERAGES, payload})
  }
