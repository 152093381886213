export const types={
    FETCH_PLANS:'GST/FETCH_PLANS',
    FETCH_PLANS_SUCCESS:'GST/FETCH_PLANS_SUCCESS',
    FETCH_PLANS_FAIL:'GST/FETCH_PLANS_FAIL'
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.FETCH_PLANS:
            return { ...state,  isLoading:true, data: null}

      case types.FETCH_PLANS_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.FETCH_PLANS_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

      default:
        return state
    }
  }

  export const actions={
      fetchPlans:(gst, coverage_id, mid, identifier)=>({type:types.FETCH_PLANS, gst, coverage_id, mid, identifier})
  }
