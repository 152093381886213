import { combineReducers } from 'redux'
import auth from './auth'
import navigationStack from './navigation_stack'
import gst from './gst'
import userstate from './state_change'
import plans from './plan'
import search_company from './search_company'
import cart from './cart'
import faq from './faq'
import invite_app from './invite_app'
import static_pricing from './static_pricing'
import verifyGst from './verifyGst';
import payment_options from './payment_options';
import set_order from './order';
import verify_order from './verify_order';
import coverages from './coverages'

const appReducer= combineReducers({
    auth,
    navigationStack,
    gst,
    userstate,
    plans,
    search_company,
    cart,
    faq,
    invite_app,
    static_pricing,
    verifyGst,
    payment_options,
    set_order,
    verify_order,
    coverages
})
export default appReducer


// const rootReducer = (state, action) => {
//     if (action.type === 'SIGNOUT_REQUEST') {
//         // for all keys defined in your persistConfig(s)
//         localStorage.removeItem('persist:root')
//         // storage.removeItem('persist:otherKey')

//         state = undefined;
//     }
//     return appReducer(state, action);
// };


//WHITELIST WHAT TO PERSIST (INCLUDE THE REDUCER FORM COMBINED REDUCER )
export const whitelisted=[
    'navigationStack',
    'userstate',
    'gst',
    'search_company',
    'checkout_order',
    'verifyGst'
]
