import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/navigation_stack'
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed, g_OtpError} from '../../utils/Constants';

function* sendLoginOtp(action) {
  try{
    let loginUrl = pageURL.LOGIN_URL;
    loginUrl = loginUrl.replace("~cId~",action.data.cc);
    loginUrl = loginUrl.replace("~mNum~", action.data.num);
    const response = yield call (apiCall, "get",loginUrl);
    if(!!response && response.status === 200 && !!response.data && response.data.success === 1)
    {
      yield put({ type: actionTypes.LOGIN_SUCCESS, authObj: {sendOtp: true,authStatus:false, mobNum:action.data.num, countryCode:action.data.cc, whatsappStatus:response.data.data && response.data.data.whatsapp}});
    }
    else{
      yield put({ type: actionTypes.LOGIN_FAILURE, authObj: {sendOtp: false,authStatus:false, mobNum:action.data.num, countryCode:action.data.cc, whatsappStatus:null}});
    }
    //const response = yield axios.get(`http://dummy.restapiexample.com/api/v1/employees`)
  }
  catch(e){
    yield put({ type: actionTypes.LOGIN_FAILURE, authObj: {sendOtp: false, authStatus:false, mobNum:action.data.num, countryCode:action.data.cc, whatsappStatus:null}});
  }
}
function* verifyLoginOtp(action){
  try{
    let verifyUrl = pageURL.VERIFY_LOGIN_OTP;
    let lreqObj = action.data;
    const lresponse = yield call (apiCall, "post",verifyUrl, lreqObj);
    
    var authObj = {sendOtp: "Done",authStatus:false, mobNum:action.data.mobile_no, countryCode:action.data.country_id};
    if(!!lresponse && lresponse.status === 200 && !!lresponse.data)
    {
        if(lresponse.data.success === 1)
        {
            //  localStorage.setItem('token',lresponse.data.token)
          yield put({ type: actionTypes.VERIFY_LOGIN_SUCCESS, authObj: {...authObj , authStatus: true, authErrMsg: "", user: lresponse.data}});
        }
        else if(lresponse.data.success === 0 && !!lresponse.data.msg)
        {
          yield put({ type: actionTypes.VERIFY_LOGIN_FAIL, authObj: {...authObj , authStatus: false, authErrMsg: lresponse.data.msg}});
        }
        else{
          yield put({ type: actionTypes.VERIFY_LOGIN_FAIL, authObj: {...authObj , authStatus: false, authErrMsg: g_Failed}});
        }
    }
    else{
        yield put({ type: actionTypes.VERIFY_LOGIN_FAIL, authObj: {...authObj , authStatus: false, authErrMsg:lresponse.data.msg || g_Failed}});
    }
  }
  catch(e){
    yield put({ type: actionTypes.VERIFY_LOGIN_FAIL, authObj: {...authObj , authStatus: false, authErrMsg: g_Failed}});
  }
}


function* LogoutSessionF(action){
  try{
    let loginUrl = pageURL.LOGOUT_SESSION;
    const response = yield call (apiCall, "get", loginUrl);
    if(!!response && response.status === 200 && !!response.data && response.data.success === 1)
    {
      yield put({ type: actionTypes.LOGOUT_REQUEST_SUCCESS, data:response.data});
    }
    else{
      yield put({ type: actionTypes.LOGOUT_REQUEST_FAIL,  data:response.data});
    }
  }
  catch(e){
    yield put({ type: actionTypes.LOGOUT_REQUEST_FAIL, data:{status:0}});
  }
}

function* patchWhatsapp(action){
  try{
    let patchUrl = pageURL.WHATSAPP_PATCH_URL;
    // patchUrl = patchUrl.replace("~bool~",action.bool);
    const response = yield call (apiCall, "patch", patchUrl, {whatsapp_status:action.bool});
    if(!!response && response.status === 200 && !!response.data && response.data.success === 1)
    {
      yield put({ type: actionTypes.PATCH_WHATSAPP_SUCCESS, data:response.data});
    }
    else{
      yield put({ type: actionTypes.PATCH_WHATSAPP_FAIL,  data:{status:0}});
    }
  }
  catch(e){
    yield put({ type: actionTypes.PATCH_WHATSAPP_FAIL, data:{status:0}});
  }
}

export function* purchaseWatcher() {
     yield takeLatest(actionTypes.LOGIN_REQUEST, sendLoginOtp)
}
export function* purchaseVerifyOTP() {
  yield takeLatest(actionTypes.VERIFY_LOGIN_REQ, verifyLoginOtp)
}
export function* LogoutSession() {
  yield takeLatest(actionTypes.LOGOUT_REQUEST, LogoutSessionF)
}

export function* WhatsappPatch() {
  yield takeLatest(actionTypes.PATCH_WHATSAPP, patchWhatsapp)
}