import React from "react";
import './aboutUs.scss'


const AboutUs=(props)=>{

    return (<>
        <div className="about-us-top-div">
            <h3>{props?.data?.label}</h3>
            <p className="p-div">{props?.data?.content}</p>
        </div>
    </>)
}
export default AboutUs