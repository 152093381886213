import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/order'
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';

//GET EMP
function* fetchOrder(action) {
    try{
        let gstUrl=pageURL.SET_ORDER;
        // gstUrl=gstUrl.replace('~gstin~', action.gst)
        const response = yield call (apiCall, "post", gstUrl, action.obj);
        if(response.data.success===1){
         yield put({ type: actionTypes.SET_ORDER_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.SET_ORDER_FAIL, data:response.data});
        }
    }catch(e){
         if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.SET_ORDER_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.SET_ORDER_FAIL, data:g_Failed});
        }
    }

}
export function* orderWatcher() {
     yield takeLatest(actionTypes.SET_ORDER, fetchOrder)
}
