import { Validator } from "../../utils/Validator"


export const types={
    SET_STATE:'STATE/SET_STATE',
    SET_PLAN:'STATE/SET_PLAN',
    UPDATE_DEMOGRAPHIC:'STATE/UPDATE_DEMOGRAPHIC',
    RESET_OBJECT: 'STATE/RESET_OBJECT',
    NAVIGATION_SELECTED: 'STATE/NAVIGATION_SELECTED',
    DASHBOARD_STATUS:'STATE/DASHBOARD_STATUS',
    SET_COVERAGES:'STATE/SET_COVERAGES',
    SET_DEFAULT_COVERAGES:'STATE/SET_DEFAULT_COVERAGES',
    SET_PREFILL_DATA:'STATE/SET_PREFILL_DATA',
    RESET_STATE:'STATE/RESET_STATE',

    WHATSAPP_STATUS:'STATE/WHATSAPP_STATUS',
    UPDATE_MID:'STATE/UPDATE_MID',
    UPDATE_IDENTIFIER:'STATE/UPDATE_IDENTIFIER',
    CHANNEL_AGE_RANGE:'STATE/CHANNEL_AGE_RANGE',
    CHANNEL_DATA:'STATE/CHANNEL_DATA'
}

export const initialState = {
    verify:{
        Phone:'',
        validPhone:false
    },
    gstDetails:{
        CompanyName:'',
        validCompanyName:false,
        GstNumber:'',
        validGstNumber:false,
    },
    companyDetails:{
        Name:'',
        validName:false,
        Email:'',
        validEmail:false,
        TotalEmployees:'',
        validTotalEmployees:false
    },
    addPeople:{},
    planSelected:{
        coverage_ids:[],
        plan_id:"", //standard_1A_c1_c2_c3_c4_c5 array
        selected_plan_id:"", //sepcific plan
        pricing_name:"", //OPAL
        policy_type:"",
        range:"", //18-35
        demographicRange:[]
    },
    navigationSelected:"Home",
    dashBoardAccess:false,
    coverages:[],
    defaultCoverages:[],
    whatsapp:false,
    mid:'',
    identifier:'',
    channelAgeRange:'',
    channelData:''
  }


  export default (state = initialState, action) => {
    switch (action.type) {
      case types.SET_STATE:
          let validation=Validator(action.name, action.value)
            return { ...state,
                        [action.key]:{...state[action.key],
                                        [action.name]:action.value,
                                        ['valid'+action.name]:validation
                                     }
                    }
      case types.SET_PLAN:
            const{coverage_ids, plan_id, pricing_name, policy_type, range, demographicRange, features, insured_amount, selected_plan_id, group_id}=action.data
            return {
                ...state,
                planSelected:{
                    coverage_ids,
                    plan_id, //array standard_1A_c1_c2_c3_c4_c5
                    pricing_name, //OPAL
                    selected_plan_id, //specfic
                    policy_type,
                    group_id,
                    range,
                    demographicRange,
                    features,
                    insured_amount
                }
            }

      case types.UPDATE_DEMOGRAPHIC:
        //   
          let validationCheck=Validator(action.name, action.value)
          let {planSelected} = state;
          planSelected.demographicRange = action.data.dataArr;
          return {...state, planSelected, [action.data.key]:{...state[action.data.key],
                          [action.data.name]:action.data.value,
                          ['valid'+action.data.name]:validationCheck
                       }
                  };

      case types.RESET_OBJECT:
      return { ...state,
                  [action.key]: initialState[action.key]

              };

      case types.NAVIGATION_SELECTED:
        return { ...state,
            navigationSelected:action.key
        }

      case types.DASHBOARD_STATUS:
        return { ...state,
            dashBoardAccess:action.bool
        }
        
      
      case types.SET_COVERAGES:
        return {...state,
          coverages:[...action.data]}

       case types.SET_DEFAULT_COVERAGES:
          return {...state,
            defaultCoverages:[...action.data]}

      case types.RESET_STATE:
        return initialState

      case types.SET_PREFILL_DATA:
          let preState={...state}
          const{business_contact_number, gstin, admin_name, business_email, total_employees}=action.data;
          
          if(business_contact_number && Validator("Phone", business_contact_number)){
            preState.verify.Phone=business_contact_number;
            preState.verify.validPhone=true;
          }else{
            preState.verify.Phone="";
            preState.verify.validPhone=false;
          }

          if(gstin && Validator("GstNumber", gstin)){
            preState.gstDetails.GstNumber=gstin;
            preState.gstDetails.validGstNumber=true;
          }else{
            preState.gstDetails.GstNumber="";
            preState.gstDetails.validGstNumber=false;
          }

          if(admin_name && Validator("Name", admin_name)){
            preState.companyDetails.Name=admin_name;
            preState.companyDetails.validName=true;
          }else{
            preState.companyDetails.Name="";
            preState.companyDetails.validName=false;
          }

          if(business_email && Validator("Email", business_email)){
            preState.companyDetails.Email=business_email;
            preState.companyDetails.validEmail=true;
          }else{
            preState.companyDetails.Email="";
            preState.companyDetails.validEmail=false;
          }

          if(total_employees && Validator("TotalEmployees", total_employees)){
            preState.companyDetails.TotalEmployees=total_employees;
            preState.companyDetails.validTotalEmployees=true;
          }else{
            preState.companyDetails.TotalEmployees="";
            preState.companyDetails.validTotalEmployees=false;
          }

      return{...preState}


      case types.WHATSAPP_STATUS:
        return { ...state,
            whatsapp:action.bool
        }

      case types.UPDATE_MID:
        return { ...state,
            mid:action.value
        }

      case types.UPDATE_IDENTIFIER:
        return { ...state,
            identifier:action.value
        }

      case types.CHANNEL_AGE_RANGE:
        return { ...state,
            channelAgeRange:action.range
        }

      case types.CHANNEL_DATA:
        return { ...state,
            channelData:action.data
        }


        
      default:
        return state
    } 
  }

  export const actions={
      setState:(key, name, value)=>({type:types.SET_STATE, key, name, value}),
      setPlan:(data)=>({type:types.SET_PLAN, data}),
      updateDemographicRange:(data, key, name, value)=>({type:types.UPDATE_DEMOGRAPHIC, data, key, name, value}),
      resetObject:(key)=>({type:types.RESET_OBJECT,key}),
      setNavSelected:(key)=>({type:types.NAVIGATION_SELECTED, key}),
      setDashbordAccess:(bool)=>({type:types.DASHBOARD_STATUS, bool}),
      setCoverages:(data)=>({type:types.SET_COVERAGES, data}),
      setDefaultCoverages:(data)=>({type:types.SET_DEFAULT_COVERAGES, data}),
      setPrefillState:(data)=>({type:types.SET_PREFILL_DATA, data}),
      resetState:()=>({type:types.RESET_STATE}),
      whatsappStatus:(bool)=>({type:types.WHATSAPP_STATUS, bool}),
      updateMid:(value)=>({type:types.UPDATE_MID, value}),
      updateIdentifier:(value)=>({type:types.UPDATE_IDENTIFIER, value}),
      setChannelAgeRange:(range)=>({type:types.CHANNEL_AGE_RANGE, range}),
      addChannelData:(data)=>({type:types.CHANNEL_DATA, data}),
  }
