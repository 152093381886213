import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/static_pricing'
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';
import { provider } from '../../utils/Env';

//GET EMP
function* fetchPricing(action) {
    try{ 
        let priceUrl=pageURL.FETCH_STATIC_PRICING;
        let priceUrl2 = priceUrl.replace('~PLAN_IDS~', encodeURIComponent(action.data.group_id));
        let url = priceUrl2.replace('~POLICY~', encodeURIComponent(action.data.policy));
        
        const response = yield call (apiCall, "get", url);
        if(response.data.success===1){
         yield put({ type: actionTypes.FETCH_STATIC_PRICING_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.FETCH_STATIC_PRICING_FAIL, data:response.data});
        }

    }catch(e){
        if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.FETCH_STATIC_PRICING_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.FETCH_STATIC_PRICING_FAIL, data:g_Failed});
        }
    }

}
export function* StaticPricingWatcher() {
     yield takeLatest(actionTypes.FETCH_STATIC_PRICING, fetchPricing)
}