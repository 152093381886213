import React, { useEffect, useState } from 'react';
// import {Helmet} from "react-helmet";
import './App.css';
import './globalStyle.scss';
import Routes, { urls } from './routes/routes';
import Toast from './Input-controls/Toast/Toast';
import { CurrentVersion } from './utils/Env';
import { ResetData, getQueryStringParams } from './utils/Utils';
// import Popup from './components/Common/ConfirmationPopUp/Popup';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {actions as nav_logout} from './redux/ducks/navigation_stack';
import {actions as resetCompany} from './redux/ducks/search_company';
import {actions as user_state} from './redux/ducks/state_change';
// import Coverage from './layouts/Coverage/Coverage';
// import FitnessRewards_Details from './layouts/Benefits-Details/FitnessRewards_Details';
import Loader from './utils/Loader/Loader';
import {Clevertap} from './utils/Utils'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App(props) {

  useEffect(() => {
    // freshdesk widged hidden
    // window.FreshworksWidget('hide', 'launcher');
    
    let prev_version=localStorage.getItem('onsurity:version')
    if(prev_version){
      if(prev_version!==CurrentVersion){
        localStorage.setItem('onsurity:version', CurrentVersion)
        props.resetState()
        props.resetCompany();
        props.logout()
        ResetData()
      }
    }else{
      localStorage.setItem('onsurity:version', CurrentVersion)
    }
  }, [])

 const[loading, setLoading]=useState(false)


  useEffect(() => {
    if(props.location.search && props.location.pathname==urls.HOME){
      let obj=getQueryStringParams(props.location.search)

      if('prefill' in obj && obj.prefill==1){
        props.resetState();
        props.resetCompany();
        props.logout()
        props.setPrefillState(obj)
        window.history.pushState("object or string", "Title", "/"+window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]);

        //for LGE homescreen
        if("parent" in obj && obj.parent=="LGE"){
          localStorage.setItem("LG", 1)
          setLoading(true)
          setTimeout(()=>{
            window.location.reload();
          },1000)
        }
      }

      if(obj && "utm_source" in obj){
        Clevertap("web_purchase_nowEmail",{
            ...obj,
            "path":window.location.href
           })

        if('utm_campaign' in obj && obj.utm_campaign=="mediascopy"){
          localStorage.setItem('mediascopy','1')
        }

    }else{

      Clevertap("web_purchase_now",{

          "path":window.location.href,
          "utm_source":'organic',
          "utm_medium":'organic',
          "utm_campaign":'ONS_Unknown'

      })

    }
    }
    else{

      Clevertap("web_purchase_now",{

          "path":window.location.href,
          "utm_source":'organic',
          "utm_medium":'organic',
          "utm_campaign":'ONS_Unknown'

      })

    }
    
    if(props.location.search && props.location.pathname==urls.VERIFY_PHONE){

      let obj=getQueryStringParams(props.location.search)

      if('utm_campaign' in obj && obj.utm_campaign=="mediascopy"){
        localStorage.setItem('mediascopy','1')
      }
      
    }
    // else if(!props.location.search && props.location.pathname==urls.HOME){
    //   localStorage.removeItem("LG")
    // }
  }, [])


// listen to storage event
window.addEventListener('storage', function(event){
  // do what you want on logout-event
  if (event.key == 'surity:log') {
    let loginFlag=localStorage.getItem('surity:log')
    if(loginFlag==0){
      props.resetState();
      props.resetCompany();
      ResetData()
      localStorage.removeItem('surity:log');

      setTimeout(function(){
        window.location.reload();
      },500);

    }
  }
});

window.addEventListener( "pageshow", function ( event ) {
  var lpathName = window.location.pathname;
  var historyTraversal = event.persisted ||
                         ( typeof window.performance != "undefined" &&
                              window.performance.navigation.type === 2 ) &&
                              (lpathName == urls.PAYMENT || lpathName == urls.CHECKOUT || lpathName == urls.ADD_PEOPLE);
  if ( historyTraversal ) {
    // Handle page restore.
    window.location.reload();
  }
});


    return(
      <>
        {loading && <Loader FullScreen={true}/> }
       {/* <Helmet>
         <meta charSet="utf-8" />
          <title>Onsurity</title>
      </Helmet>  */}
      {/* GENERIC TOAST SERVICE */}
      <Toast/>
      {/* ALL THE APP ROUTES */}
     <Routes/>

      </>
      )

}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({
      ...nav_logout,
      ...user_state,
      ...resetCompany
      //ADD IF ITS FROM DIFFRENT ACTION FILE
  }, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
