import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/invite_app';
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';

//GET EMP
function* fetchCompany(action) {
    try{
        let inviteUrl = pageURL.INVITE_APP_URL;//"https://run.mocky.io/v3/39e72f92-5a9f-43ef-bad0-bb95c0eed758";
        //pageURL.FETCH_COMPANY;
        inviteUrl = inviteUrl.replace('~mNum~', action.pMobNum);
        const response = yield call (apiCall, "get", inviteUrl);
        // 
        if(response.data.statusCode===1){
         yield put({ type: actionTypes.INVITE_APP_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.INVITE_APP_FAIL, data:response.data});
        }

    }catch(e){
        if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.INVITE_APP_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.INVITE_APP_FAIL, data:{status:0, data:{msg:g_Failed}}});
        }
    }

}
export function* inviteAppWatcher() {
     yield takeLatest(actionTypes.INVITE_APP, fetchCompany)
}
