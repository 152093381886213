import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/gst'
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';

//GET EMP
function* fetchGST(action) {
    try{
        let gstUrl=pageURL.FETCH_GSTIN;
        gstUrl=gstUrl.replace('~gstin~', action.gst)
        const response = yield call (apiCall, "get", gstUrl);
        if(response.data.success===1){
         yield put({ type: actionTypes.FETCH_GSTIN_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.FETCH_GSTIN_FAIL, data:response.data});
        }
    }catch(e){
        // if(e.response.status===401){
        //     window.location.href=""
        // }
        // else

         if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.FETCH_GSTIN_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.FETCH_GSTIN_FAIL, data:g_Failed});
        }
    }

}
export function* gstWatcher() {
     yield takeLatest(actionTypes.FETCH_GSTIN, fetchGST)
}
