export const CheckIndianNumber = (pNumber) => {
  let lcheck = /^[564789]\d{9}$/;
  if(lcheck.test(pNumber)){
    return true;
  }
  else {
    return false;
  }
}
export const CheckMobileOTP = (pOtp) => {
  let lcheck = /^\d{4}$/;
  if(lcheck.test(pOtp)){
    return true;
  }
  else {
    return false;
  }
}

export const CheckGstin = (gstin) => {
    return /^[a-zA-Z0-9]{15}$/.test(gstin)? true: false
}

export const Validator=(name, value)=>{

  switch (name) {
    case "Phone":
        return (/^\d{10}$/).test(value)? true: false
    case "GstNumber":
        return /^[a-zA-Z0-9]{15}$/.test(value)? true: false
    case "CompanyName":
        return  true
    case "Name":
        return /^[A-Za-z ]+$/.test(value) ? true : false
    case "Email":
        return /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,20})$/.test(value.trim()) ? true : false
    case "TotalEmployees":
        if(value==="" || (parseInt(value) > 1 && parseInt(value) < 10001))
        {
          return true
        }
        else{
          return false
        }
       //return (/[3-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|10000/).test(value)?true:false
    default:
        return false
  }
}
