import React from 'react'
import {Route, withRouter } from 'react-router-dom';
import Seo from "../utils/Seo";


 function CustomRoute({component:ComponentToRender, ...rest}) {
    return (
        <>
        <Route {...rest} render={()=>(
            <>
            <Seo
                title={rest.title?rest.title:'default'}
                description={rest.description?rest.description:'default'}
                keywords={rest.keywords?rest.keywords:'Onsurity'}
                url={rest.path}
                /> 
             <ComponentToRender {...rest}/>
            </>
        )} />
        </>
    )
}

export default withRouter(CustomRoute)