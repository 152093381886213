import React, { useEffect, useState } from 'react'
import './PrivacyPolicy.scss';
import Header from '../../components/Home/HeaderSection/Header'
import { Helmet } from 'react-helmet';
import { urls } from '../../routes/routes';
import Axios from 'axios';
import { urlPrefixGlobal } from '../../utils/MicroServiceURL';
import { GetTermsAndPrivacy } from '../../utils/Utils';
import Loader from '../../utils/Loader/Loader';


export default function Privacy() {

    const[checkHead,setCheckHead]=useState(true);
    const [tncDetails, settncDetails] = useState()

    useEffect(()=>{
      const {pathname}=window.location;
      if(pathname==urls.PRIVACY_POLICY_MOB){
            setCheckHead(false)
      }
    },[])

    useEffect(() => {

        GetTermsAndPrivacy()
          .then(data=>{
               settncDetails({
                  ...data,
                })
          })

      }, [])

    return (
        <>
        <div>
            {checkHead &&<Header />}

                
            {!tncDetails &&
            <Loader/>}

            <iframe src={tncDetails && tncDetails.privacy_policy} 
                title="description"
                className="privacy-polcy">
            </iframe>

        </div>
        </>
    )
}
