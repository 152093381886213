import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/verify_order';
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';
import { provider } from '../../utils/Env';

//GET EMP
function* fetchPaymtOpt(action) {
    try{
        let verifyUrl=pageURL.VERIFY_ORDER;
        const response = yield call (apiCall, "post", verifyUrl, action.obj);
        // 
        if(response.data.success===1){
         yield put({ type: actionTypes.VERIFY_ORDER_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.VERIFY_ORDER_FAIL, data:response.data});
        }

    }catch(e){
        if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.VERIFY_ORDER_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.VERIFY_ORDER_FAIL, data:g_Failed});
        }
    }

}
export function* verifyOrderWatcher() {
     yield takeLatest(actionTypes.VERIFY_ORDER, fetchPaymtOpt)
}
