import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/plan'
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';
import { provider } from '../../utils/Env';
import { convertUrlToSourceName, isSameDomain } from '../../layouts/PlanPurchase/util';

//GET EMP
function* fetchPLAN(action) {
    try{
        let gstUrl=pageURL.FETCH_PLANS;
        let payload={
          gstin:action.gst, 
          coverage_ids:[...action.coverage_id]
        }
        // payload.source='godrej'
    
          if(convertUrlToSourceName()==='Godrej'){
            payload.source=convertUrlToSourceName()
        }
         
      
        if(action.mid){
          payload.mid=action.mid
          payload.identifier=action.identifier
        }
        // const response = yield call (apiCall, "post", gstUrl, {gstin:action.gst, gender:'all', coverage_ids:[...action.coverage_id], provider: provider});
        const response = yield call (apiCall, "post", gstUrl, payload);
        if(response.data.success===1){
         yield put({ type: actionTypes.FETCH_PLANS_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.FETCH_PLANS_FAIL, data:response.data});
        }

    }catch(e){
        if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.FETCH_PLANS_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.FETCH_PLANS_FAIL, data:g_Failed});
        }
    }

}
export function* plansWatcher() {
     yield takeLatest(actionTypes.FETCH_PLANS, fetchPLAN)
}
