import React from 'react'
import './Toggle.css'

export default function Toggle(props) {
    return (
        <div>
             <label className="switch_toggle">
                <input onClick={props.onClick} checked={props.checked} className="Check_Toggle" type="checkbox" />
                <span className="slider_togglr round_toggle"></span>
            </label>
        </div>
    )
}
