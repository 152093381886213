import React from "react";
import { useHistory } from "react-router-dom";

// Components
import BenefitsCard from "./Benefits-Card/BenefitsCard";
import WhyOnsurityCard from "./Why-Onsurity-Card/WhyOnsurityCard";
import Testimonial from "./Testimonial/Testimonial";

// Styles
import "./Partner-Page-1.scss";

function PartnerPage1(props) {
  const {
    topHeaderLogo,
    banner,
    theme,
    onsurityBenefits,
    whyOnsurityContent,
    testimonials,
  } = props.partnerData;

  const history = useHistory();

  const { primaryColor, secondaryColor, ctaTextColor } = theme;

  const { bannerTextArea, bannerImage } = banner;
  const { heading1, heading2, heading3 } = bannerTextArea;

  return (
    <div className="partner-page">
      <div className="top-header">
        <div className="logo1">
          <img src={topHeaderLogo} alt="Top Header Logo" />
        </div>

        <div className="talk-to-us-cta">
          <button
            onClick={() => {
              window.open("https://health.onsurity.com/request-a-demo-godrej");
            }}
            style={{ backgroundColor: secondaryColor, color: ctaTextColor }}
          >
            Talk to us
          </button>
        </div>
      </div>

      <div className="banner">
        <div className="banner-text">
          <h1 className="heading1">{heading1}</h1>
          <p style={{ color: primaryColor }} className="heading2">
            {heading2}
          </p>
          <p
            className="heading3"
            style={{ color: primaryColor }}
            dangerouslySetInnerHTML={{ __html: heading3 }}
          ></p>

          <div className="banner-cta">
            <button
              onClick={() => history.push("/checkout/verify")}
              className="banner-cta"
              style={{ backgroundColor: secondaryColor, color: ctaTextColor }}
            >
              Start your Healthcare Journey Today
            </button>
          </div>
        </div>
        <div className="banner-image">
          <img src={bannerImage} alt="Banner Image Logo" />
        </div>
      </div>

      <div className="onsurity-benefits">
        <div className="section-heading">
          <h2>Onsurity Benefits</h2>
        </div>
        <div className="onsurity-benefits-content">
          {onsurityBenefits.map((onsurityBenefit) => {
            return (
              <BenefitsCard
                topHeader={onsurityBenefit.topHeader}
                cardPoints={onsurityBenefit.cardPoints}
                priceContent={onsurityBenefit.priceContent}
                btnContentObj={onsurityBenefit.btnContentObj}
              />
            );
          })}
        </div>
      </div>

      <div className="why-onsurity">
        <div className="section-heading">
          <h2>Why Onsurity?</h2>
        </div>
        <div className="why-onsurity-content">
          {whyOnsurityContent.map((wo, idx) => {
            if (idx < 3) {
              return (
                <div className="parent-wrapper">
                  <WhyOnsurityCard imgUrl={wo.imgUrl} cardText={wo.cardText} />
                </div>
              );
            }
          })}
        </div>

        <div className="why-onsurity-content">
          {whyOnsurityContent.map((wo, idx) => {
            if (idx >= 3) {
              return (
                <div className="parent-wrapper">
                  <WhyOnsurityCard imgUrl={wo.imgUrl} cardText={wo.cardText} />
                </div>
              );
            }
          })}
        </div>
      </div>

      <div className="testimonials-section">
        <div className="section-heading">
          <h2>Here’s what our customers are saying!</h2>
        </div>
        <div className="testimonials-content">
          {testimonials.map((testimonial) => {
            return (
              <div className="parent-wrapper">
                <Testimonial
                  testimonialContent={testimonial.testimonialContent}
                  author={testimonial.author}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PartnerPage1;
