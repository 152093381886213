import { useEffect } from "react"
import TagManager from "react-gtm-module"
import { useLocation } from "react-router"

let pathName=''
export const GTMWrapper = ({children}) => {
    const location=useLocation()
    useEffect(() => {
        if(location.pathname!==pathName){
           const tagManagerArgs = {
               dataLayer: {
                   event: 'page-view',
                   path:location.pathname,
                   pageHref:window.location.href
               }
           }
           TagManager.dataLayer(tagManagerArgs)
            pathName=location.pathname
        }
        
   }, [location])
    return (
        children
    )
}

