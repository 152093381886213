import React, {useEffect} from 'react'

export default function Blog() {

    useEffect(() => {
        window.location.href="https://blog.onsurity.com/"
    }, [])

    return (
        <div>
            
        </div>
    )
}
