import React,{useEffect, useState} from 'react'
import './TermsOfUse.scss';
import Header from '../../components/Home/HeaderSection/Header';
import { urls } from '../../routes/routes';
import { GetTermsAndPrivacy } from '../../utils/Utils';
import Loader from '../../utils/Loader/Loader';


export default function TermsOfUse() {
    const[checkHead,setCheckHead]=useState(true);

    useEffect(()=>{
      const {pathname}=window.location;
      if(pathname==urls.TERMS_OF_USE_MOB){
            setCheckHead(false)
      }
    },[])


    const [tncDetails, settncDetails] = useState()

    useEffect(() => {

        GetTermsAndPrivacy()
          .then(data=>{
               settncDetails({
                  ...data,
                })
          })

      }, [])

    return (
        <>

        <div>
            {checkHead && <Header />}
            
            {!tncDetails &&
            <Loader/>}

            <iframe  src={tncDetails && `${tncDetails.tnc}?random=${Math.random()}`} 
                title="description"
                className="privacy-polcy">
            </iframe>
        </div>
        </>
    )
}
