import React, {useEffect, useState} from 'react'
import axios from 'axios'

// Components
import PartnerPage2 from './Partner-Page-2'
import PartnerPage1 from './Partner-Page-1';
import FooterComponent from '../../components/Common/Footer/footer';
import './partnerPage.scss'
import { setSessionPartner } from '../PlanPurchase/util';


const PartnerPage = () => {
    const [partnerData, setPartnerData] = useState()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const getPartnerData = async () => {
            const res = await axios({
                method: 'get',
                url: 'https://static-assets.onsurity.com/partner-page-json/godrej/godrej-partner-page-data-v2.json',
                withCredentials: false,
            });
            setPartnerData(res.data.godrej)
            setIsLoading(false)
        }

        getPartnerData()
        setSessionPartner(document?.referrer)
      }, [])
    return (
        <>
            {
                !isLoading ? (
                    <>
                        <PartnerPage1 partnerData={partnerData} />
                        <PartnerPage2 partnerData={partnerData} />
                        <div className='footer-wrapper'>
                            <FooterComponent/>
                        </div> 
                    </>
                ) : ''
            }

        </>
    )
}
export default PartnerPage