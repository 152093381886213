import React from "react";
import CustomSlider from "../../components/CustomSlider/slider";
import PartnerCard from "./partnerCard/partnerCard";
import "./partnerPage.scss";
import { isMobile } from "react-device-detect";
import AboutUs from "./partnerSection/aboutUs/aboutUs";
import CollapseCard from "./collapseCard/collapseCard";

// const partner = {
//   channelpartner: {
//     theam: {
//       primaryColor: "",
//       secondryColor: "",
//     },
//     banner: {
//       bannerTextArea: {
//         heading1:
//           "Grow your business with happier and healthier employees, powered by Godrej",
//         heading2: "#HealthkiSurity with Onsurity'",
//         heading3:
//           "<p>Plans starting <span>₹135*</span> per Month per Employee</p>",
//       },
//       banerImage: {
//         url: "https://health.onsurity.com/hs-fs/hubfs/Here-image…ng?width=1594&height=1250&name=Here-image-new.png",
//       },
//     },
//     onsurityBenefits: {
//       label: "Onsurity Benefits",
//       data: [
//         {
//           topHeader: "Healthcare Membership Onyx",
//           cardPoints: [
//             "Group Health Insurance for members - 1 lakh",
//             "Group Personal Accidental Insurance - 3 lakh",
//             "Up to 20% discount on prescribed medicines",
//             "Up to 65% off on Health checkups",
//             "5 Free Doctor teleconsultations every month",
//           ],
//           priceContent: "Offer Price: ₹ 400/100 for 1 lakh of Sum Insured",
//           btnContentObj: {
//             title: "Click Me!",
//             url: "https://youtube.com",
//           },
//         },
//       ],
//     },
//     whyOnsurityContent: {
//       label: "Why Onsurity?",
//       data: [
//         {
//           imgUrl:
//             "https://static-assets.onsurity.com/channel-partner/claims_assistance_ic.png",
//           cardText: "End to end claims assistance",
//         },
//       ],
//     },
//     testimonials: {
//       label: "Here’s what our customers are saying!",
//       data: [
//         {
//           author: {
//             name: "Ankur Warikoo",
//             organization: "nearbuy.com",
//             designation: "Mentor, Angel Investor, Public Speaker",
//             imgUrl: "https://health.onsurity.com/hubfs/testmonial-1.png",
//           },
//           testimonialContent:
//             "Closed this in Feb with @Onsurity in record time. (Trust them to ensure that). 33 lives covered, one claim already processed. Immaculate process and service orientation.",
//         },
//       ],
//     },
//     startWithOnsurity: {
//       label: "Get Started with Onsurity",
//       subTitle:
//         "In just 4 simple steps get quality healthcare benefits for your employees",
//       data: [
//         {
//           imgUrl:
//             "https://static-assets.onsurity.com/frontend-ui-purchseflow/onsuritybenifits/1-com.png",
//           content: "Share your company details",
//         },
//         {
//           imgUrl:
//             "https://static-assets.onsurity.com/frontend-ui-purchseflow/onsuritybenifits/teamwork.png",
//           content:
//             "Select a healthcare plan that best meets your team’s needs and budget",
//         },
//         {
//           imgUrl:
//             "https://static-assets.onsurity.com/frontend-ui-purchseflow/onsuritybenifits/payment-1.png",
//           content:
//             "Monthly or annual payment – choose a model that aligns with your working capital",
//         },
//         {
//           imgUrl:
//             "https://static-assets.onsurity.com/frontend-ui-purchseflow/onsuritybenifits/dashboard.png",
//           content:
//             "Add and manage your team members in Teamsure dashboard on the go",
//         },
//       ],
//     },
//     partners: {
//       label: "Trusted by 3,500+ SMEs, startups and emerging businesses",
//       data: [
//         "https://static-assets.onsurity.com/frontend-ui-purchseflow/partnerlogo/lybrate_logo.svg",
//         "https://static-assets.onsurity.com/frontend-ui-purchseflow/partnerlogo/medlife_logo.svg",
//         "https://static-assets.onsurity.com/frontend-ui-purchseflow/partnerlogo/nymble_logo.svg",
//         "https://static-assets.onsurity.com/frontend-ui-purchseflow/partnerlogo/webengage_logo.svg",
//         "https://static-assets.onsurity.com/frontend-ui-purchseflow/partnerlogo/whitehat_logo.svg",
//         "https://static-assets.onsurity.com/frontend-ui-purchseflow/partnerlogo/workex_logo.svg",
//       ],
//     },
//     aboutOnsurity: {
//       label: "About Onsurity",
//       content:
//         "Founded in 2020, Onsurity simplifies employee healthcare with technology solutions that make healthcare work for everyone. With 5,00,000+ members and over 3500+ companies and partners including WhiteHat Jr, Visa, Jupiter Money, Pantaloons, MagicPin, DealShare, among others—Onsurity is democratising the healthcare experience for SME and their workforce across India. Onsurity is India’s top 10 Innovative Fintech Start-up recognised by The Digital Fifth, has built smarter technology that’s easy to use, gives people an advocate in their health journey, and helps employers unblock working capital by managing cost.",
//     },
//     frequentAskedQes: {
//       label: "Frequently asked questions about Onsurity Edge Credits Program",
//       data: [
//         {
//           question: "What is Onsurity’s HealthCare membership?",
//           ans: {
//             label:
//               "Onsurity’s HealthCare membership is an employee healthcare and wellness membership that includes a host of benefits including:",
//             allPoints: [
//               {
//                 isNested: false,
//                 value:
//                   "India’s first monthly subscription healthcare platform ",
//               },
//               {
//                 isNested: false,
//                 value: "Group health and personal accidental insurance",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Order medicines and get health-check- ups at discounted prices",
//               },
//               {
//                 isNested: false,
//                 value: "Free specialised doctor consultations",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "India’s first monthly subscription healthcare platform ",
//               },
//               {
//                 isNested: true,
//                 value: "Wellness benefits",
//                 morePoint: [
//                   "Mental wellness sessions ",
//                   "Health and fitness webinars",
//                   "Dental and vision plans",
//                   "Gym memberships",
//                   "Live webinars by industry experts",
//                 ],
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Addition of family members in the plan with a personal purchase",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Plan upgradation for greater benefits through personal purchase",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Access to the Good Doctors Team for: hospitalization and claim/reimbursement assistance",
//               },
//             ],
//           },
//         },
//         {
//           question: "Why choose TeamSure by Onsurity?",
//           ans: {
//             label:
//               "“An Organisation’s Soul is in a happy Team”. Here are a few reasons why TeamSure is a notch above the others:",
//             allPoints: [
//               {
//                 isNested: false,
//                 value:
//                   "Maintains efficiency of your workforce which helps you to achieve your company’s growth.",
//               },
//               {
//                 isNested: false,
//                 value: "Higher retention rates for your team members.",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Better affordability and working capital management due to monthly subscription.",
//               },
//               { isNested: false, value: "Digital Enrolment & Zero paperwork." },
//               {
//                 isNested: false,
//                 value: "Just 3 minimum number of team members.",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Include every team member: Employees, freelancers, temporary staff and contractors.",
//               },
//             ],
//           },
//         },
//         {
//           question: "Why Choose Onsurity as your employee healthcare partner?",
//           ans: {
//             label: `Onsurity has partnered with over 3500+ companies including SMEs, startups, and small businesses at an exponential rate. Here are the reasons why Onsurity is your trusted healthcare partner:`,
//             allPoints: [
//               {
//                 isNested: false,
//                 value: "Digital employee HealthCare and LifeCare plans.",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Inclusive of all - from employees to freelancers to contractors.",
//               },
//               { isNested: false, value: "Affordable and easy to use." },
//               {
//                 isNested: false,
//                 value: "Designed for SMEs,  startups, and emerging businesses.",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Membership is available to teams as small as 3 and above. ",
//               },
//               {
//                 isNested: true,
//                 value: "Tech-enabled platforms –",
//                 morePoint: [
//                   "Digital dashboard for employers.",
//                   "Onsurity iOS/Android app for employees.",
//                 ],
//               },
//               { isNested: false, value: "100 % paperless processes." },
//               {
//                 isNested: false,
//                 value: "Team on-boarding journey in just 2 minutes.",
//               },
//             ],
//           },
//           extratext: "Also, please feel free to check out our “Wall of Love” :",
//           locationurl: " https://onsurity.com/reviews ",
//         },
//         {
//           question:
//             "My offices are in various cities around India, are my team members in different cities covered?",
//           ans: {
//             label: `Yes. We have got the back of all your team members PAN India.`,
//           },
//         },
//         {
//           question:
//             "What details/documents are required to subscribe for the Wellness Membership?",
//           ans: {
//             label: `We just need to have your GSTIN details and some pretty basic information. No other documents are required to subscribe. `,
//           },
//         },
//         {
//           question:
//             "Is there any age restriction under the membership program?",
//           ans: {
//             label: `The entry age for the Team Member into the program for each person shall be below 65 years.`,
//           },
//         },
//         {
//           question:
//             "What financial instruments/mode of payments are allowed for the program?",
//           ans: {
//             label: `Since our membership allows you the option for monthly payment, all modes of payment with auto-debit / recurring payment facility are allowed for the program. Following are the instruments/modes of payment that support recurring payments:`,
//             allPoints: [
//               {
//                 isNested: false,
//                 value:
//                   "All Credit Cards on Visa and Mastercard network with one-time authentication. ",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Debit Cards on Visa and Mastercard network issued by ICICI Bank, Kotak Mahindra bank, Citibank and Canara Bank with one-time authentication.",
//               },
//               {
//                 isNested: false,
//                 value:
//                   "Enabling automated recurring transactions over a bank account via E-mandate registration through Netbanking or Debit Card ",
//               },
//             ],
//           },
//           extratext: "The list may be updated from time to time.",
//         },
//         {
//           question:
//             "What is the difference between Team Member only and Team Member & Family Membership?",
//           ans: {
//             label: `Under the Team Member Only membership, healthcare features and benefits are availed only by an individual team member in the organization. Under the Team Member & Family membership, healthcare features and benefits are extended to your team member as well as his/her spouse and two children aged up to 25 years. `,
//           },
//         },
//         {
//           question: "Is there a minimum team count?",
//           ans: {
//             label: `Yes, but the minimum team member count is as low as 3 people. `,
//           },
//         },
//       ],
//     },
//   },
// };

function PartnerPage2(props) {
  const partner = { channelpartner: props.partnerData };
  const responsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ];

  return (
    <>
      <div className="get-start-with-onsurity-top-div">
        <h2 className="">
          {partner?.channelpartner?.startWithOnsurity?.label}
        </h2>
        <p className="ptag">
          {partner?.channelpartner?.startWithOnsurity?.subTitle}
        </p>
        <div className="partner-card-top-div">
          <PartnerCard
            data={partner?.channelpartner?.startWithOnsurity?.data}
          />
        </div>
        <div
          onClick={() => {
            window.open("https://health.onsurity.com/request-a-demo-godrej");
          }}
          className="buttom-div"
        >
          Get A Demo
        </div>
      </div>

      <div className="slider-top-div">
        <h2 className="trusted-top-div-text">
          {partner?.channelpartner?.partners?.label}
        </h2>
        <CustomSlider
          infinite
          cssEase="linear"
          autoplaySpeed={4000}
          fade={isMobile ? true : false}
          className="sliderWrapperResponse"
          dots={true}
          speed={500}
          responsive={responsive}
          slidesToShow={isMobile ? 1 : 5}
          slidesToScroll={isMobile ? 1 : 5}
        >
          {partner?.channelpartner?.partners?.data?.map((item) => (
            <div className="slider-image-top-div">
              <img src={item} alt="logo_image" />{" "}
            </div>
          ))}
        </CustomSlider>
      </div>
      <AboutUs data={partner?.channelpartner?.aboutOnsurity} />
      <div className="question-ans-top-div">
        <h2 className="question-ans-top-div-lable">
          {partner?.channelpartner?.frequentAskedQes?.label}
        </h2>
        <CollapseCard data={partner?.channelpartner?.frequentAskedQes} />
      </div>
    </>
  );
}

export default PartnerPage2;
