import React from 'react';
import {isMobile, osName} from 'react-device-detect';
class AppRedirect extends React.Component {

 
    renderContent = () => {         
        if (isMobile) {
            if (osName === 'iOS') {
                window.location.href = "https://apps.apple.com/in/app/onsurity/id1510755114"
            } else {
                window.location.href = "https://play.google.com/store/apps/details?id=com.onsurity"
            }
        }
        return (
            <div>
                <h4 style={{ paddingTop : "100px", textAlign : "center" }}>Download our app</h4>
                <div style={{ paddingTop : "20px", textAlign : "center" }}>
                    <a href='https://play.google.com/store/apps/details?id=com.onsurity' target="_blank" rel="noopener noreferrer">
                        <img  src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1588752779/OnSurity/OnsurityAssets/Footer/google-play-badge_rff01o.png" alt="social-icons" />
                    </a>
                </div>
                <div style={{ paddingTop : "10px", textAlign : "center", paddingBottom: "50px" }}>
                    <a href='https://apps.apple.com/in/app/onsurity/id1510755114' target="_blank" rel="noopener noreferrer">
                    <img src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1588752767/OnSurity/OnsurityAssets/Footer/app-store-apple_ubyh0w.png" alt="social-icons" />
                    </a>    
                </div>
            </div>
        )
    }
     
    render() {
        return (
            this.renderContent()
        )
    }
}

export default AppRedirect;