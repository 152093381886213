import React, { useRef, useState } from 'react'
import { urls } from '../../../routes/routes';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions as navigationActions } from '../../../redux/ducks/navigation_stack'
import { bindActionCreators } from 'redux';
import { CapitalizeString } from '../../../layouts/PlanPurchase/util';
import { actions as user_state } from '../../../redux/ducks/state_change'
import { actions as resetCompany } from '../../../redux/ducks/search_company';
import { navigation } from '../../../utils/Env';
import { Clevertap, IsRedBus } from '../../../utils/Utils';
import { DashboardUrl } from '../../../utils/MicroServiceURL';
import Loader from '../../../utils/Loader/Loader';
import Toggle from '../../../Input-controls/Toggle/Toggle';
import DropDownIcon from './../../../assets/home/drop-down.svg'
import { LazyImage } from '../../../utils/LazyImage';
import './Header.scss';


function Header(props) {
    const { location } = props
    const [showText, setShowText] = useState(false);
    const [active, setActive] = useState('');


    const benifitsUri = [urls.GROUP_HEALTH_NEW, urls.TERM_LIFE_NEW, urls.GOOD_DOCTOR_NEW, urls.FITNESS_TRACKING_NEW]
    const ref = useRef("")

    const [toggle, setToggle] = useState(false)

    const HandleToggle = () => {
        setToggle(!toggle)
        setShowText(false)
        ref.current.classList.toggle('active');
    }

    const getAppFn = () => {
        let lobj = {};
        lobj.clicked = true;
        Clevertap("GetTheApp", lobj);
        props.setNavSelected(navigation.HOME)
        props.history.push({
            pathname: urls.HOME,
            from: 'getapp'
        })
    }

    const SetMyaccToggle = () => {
        let lobj = {};
        lobj.clicked = true;
        Clevertap("My_Account", lobj);
        setShowText(!showText)
    }

    const HandleDanshBoard = () => {
        window.location.href=DashboardUrl

    }

    const [logoutClicked, setLogoutClicked] = useState(false)

    const [loading, setloading] = useState(false)

    const HandleLogout = () => {

        SetMyaccToggle();
        props.resetState();
        props.resetCompany();
        props.logout();
        props.resetAuthObj();
        localStorage.setItem('surity:log', '0');
        localStorage.removeItem("token");

        setTimeout(function () {
            props.resetAuthObj();
        }, 500)

        let lgdata = localStorage.getItem("LG");

        if (lgdata !== undefined || lgdata !== null) {
            localStorage.setItem("LG", 0);
            setloading(true)
            setTimeout(() => {
                window.location.reload();
            }, 1000)
        }
    }

    const HandleWhatsapp = () => {
        props.whatsappStatus(!props.whatsapp)
        props.updateWhatsapp(!props.whatsapp === true ? 1 : 0)
    }

    const twitterClkFn = (e) => {
        let lobj = {};
        lobj.clicked = true;
        Clevertap("Twitter", lobj);
    }

    const fbClkFn = (e) => {
        let lobj = {};
        lobj.clicked = true;
        Clevertap("Facebook", lobj);
    }


    return (
        <>
            {loading && <Loader FullScreen={true} />}

            {/* for web view */}
            <div className="HomeheaderCls">
                <div className="headWeb">
                    <nav className="navbar mscrollShadow" >
                        <div className="homeWrapperComponents d-flex w-100 headerPadding">
                            <a href="/" className="logo">
                            <LazyImage height='46px' width='142px' src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1595591334/New-home-screen/Group_1328_bbme78.svg" alt="onsurity_logo" className="onsuimg" />
                            </a>
                            <ul className="main-nav" id="js-menu">
                            <li className="liLink">
                                    <a id ='navv' target='blank' href="https://health.onsurity.com/credits-program" alt="Credits Program" className={`nav-links`} >Credits Program</a>
                                </li>
                                <li className="liLink tooltip-wrapper ">
                                    <div style={{ cursor: 'pointer' }} className={`nav-links `} id="navv">Already a member?<span><LazyImage width={'24px'} height={'8px'} className="nav-drop" src={DropDownIcon} /></span></div>
                                    <div className="tooltipMenu membership-fix bottomSide">

                                        <ul className="home-list-member" style={{}}>
                                            <li style={{ marginBottom: '8px'}}>
                                                <p>Teamsure dashboard</p>
                                                <button onClick={() => {

                                                    HandleDanshBoard()

                                                }}>Login</button>
                                            </li>
                                            <li > <p>Onsurity app</p>
                                                <a href="/#app-download">
                                                    <button >Get the app</button>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="liLink tooltip-wrapper">
                                <div style={{ cursor: 'pointer' }} className={`nav-links ${benifitsUri.includes(location.pathname) && "selectedNav"}`} id="navv" > Benefits  <span><LazyImage width={'24px'} height={'8px'} className="nav-drop" src={DropDownIcon} /></span></div>
                                    <div className="tooltipMenu  bottomSide">
                                        <ul className="home-list" style={{}}>
                                            <li onClick={() => props.history.push(urls.GROUP_HEALTH_NEW)} className={`homeListLi ${location.pathname === urls.GROUP_HEALTH_NEW && "selectedNav"}`}>Group Health Insurance</li>
                                            <li onClick={() => props.history.push(urls.TERM_LIFE_NEW)} className={`homeListLi ${location.pathname === urls.TERM_LIFE_NEW && "selectedNav"}`}>Group Term Life Insurance</li>
                                            <li onClick={() => props.history.push(urls.GOOD_DOCTOR_NEW)} className={`homeListLi ${location.pathname === urls.GOOD_DOCTOR_NEW && "selectedNav"}`}>Claims Concierge</li>
                                            <li onClick={() => props.history.push(urls.FITNESS_TRACKING_NEW)} className={`homeListLi ${location.pathname === urls.FITNESS_TRACKING_NEW && "selectedNav"}`}>Fitness & Wellness Benefits</li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="liLink tooltip-wrapper">
                                <div style={{ cursor: 'pointer' }} className={`nav-links ${location.pathname === urls.ABOUT_US && "selectedNav"}`} id="navv" > About <span><LazyImage width={'24px'} height={'8px'} className="nav-drop" src={DropDownIcon} /></span></div>
                                    <div className="tooltipMenu  bottomSide">
                                        <ul className="home-list" style={{}}>
                                            <li onClick={() => props.history.push(urls.ABOUT_US)} className={`homeListLi ${location.pathname === urls.ABOUT_US && "selectedNav"}`}>About Us</li>
                                            <li className={`homeListLi`} onClick={()=>window.open('https://health.onsurity.com/newsroom/')}
                                             >Newsroom</li>
                                    
                                        </ul>
                                    </div>
                                </li>
                                <li className="liLink">
                                    <a id ='navv' target='blank' href="https://onsurity.kekahire.com/" alt="Careers" className={`nav-links`} >Careers</a>
                                </li>
                                <li className="liLink">
                                    <span onClick={() => props.history.push(urls.CONTACT_US)} style={{ cursor: 'pointer' }} className={`nav-links ${location.pathname === urls.CONTACT_US_NEW && "selectedNav"}`} id="navv" >Contact Us</span>
                                    <a title="Onsurity – Contact Us for Customer Support and Partnerships" href="https://www.onsurity.com/teamsure/contact-us" alt="Contact Us" style={{ fontSize: '0px' }}>Contact Us</a>
                                </li>

                                <li className="myaccountflex liLink">
                                    {props.auth.authStatus === true ?
                                        <button id="login-link"
                                            onClick={() => SetMyaccToggle()}
                                            className="ml-0 nav-links">My Account</button>
                                        :
                                        <button id="myaccount" className="nav-links ml-0"
                                            onClick={() => {
                                                props.history.push(urls.VERIFY_PHONE)
                                                Clevertap('web_sent_otp_purchase', { clicked: true })
                                            }}>{IsRedBus()} </button>
                                    }
                                </li>
                                {
                                    showText &&
                                    <div className="acount-details-div">
                                        <div className="wrp-account-sec">
                                            <div className="acchol-name">Hello{props.name && `, ${CapitalizeString(props.name).split(' ')[0]}`}</div>
                                            <div className="achol-numnber">+91 {props.state.Phone}</div>
                                            {props.dashboard.dashBoardAccess &&
                                                <>
                                                    <div className="account-sec-hr"></div>
                                                    <div className="dashboard-emp" onClick={() => HandleDanshBoard()}>Employer Dashboard</div>
                                                </>}
                                            <div className="account-sec-hr"></div>
                                            <div className="logout-sec mb-3" style={{ display: 'flex' }}>
                                                <div style={{ margin: 'auto 0px', marginRight: '10px' }}>WhatsApp Notification</div>
                                                <div>
                                                    <Toggle onClick={() => HandleWhatsapp()} checked={props.whatsapp} />
                                                </div>
                                            </div>
                                            <div className="account-sec-hr"></div>
                                            <div className="logout-sec mb-3" onClick={() => HandleLogout()}>Logout</div>
                                        </div>
                                    </div>
                                }



                            </ul>
                        </div>
                    </nav>
                </div>

                {/* for mobile view */}
                <div className="headMob">
                {
                                    showText &&
                                    <div className="acount-details-div-mob">
                                        <div className="wrp-account-sec">
                                            <div className="acchol-name">Hello{props.name && `, ${CapitalizeString(props.name).split(' ')[0]}`}</div>
                                            <div className="achol-numnber">+91 {props.state.Phone}</div>
                                            {props.dashboard.dashBoardAccess &&
                                                <>
                                                    <div className="account-sec-hr"></div>
                                                    <div className="dashboard-emp" onClick={() => HandleDanshBoard()}>Employer Dashboard</div>
                                                </>}
                                            <div className="account-sec-hr"></div>
                                            <div className="logout-sec mb-3" style={{ display: 'flex' }}>
                                                <div style={{ margin: 'auto 0px', marginRight: '10px' }}>WhatsApp Notification</div>
                                                <div>
                                                    <Toggle onClick={() => HandleWhatsapp()} checked={props.whatsapp} />
                                                </div>
                                            </div>
                                            <div className="account-sec-hr"></div>
                                            <div className="logout-sec mb-3" onClick={() => HandleLogout()}>Logout</div>
                                        </div>
                                    </div>
                                }
                    <nav className="navbar">
                        <span className="navbar-toggle" id="js-navbar-toggle" onClick={() => HandleToggle()}>
                            {toggle ?
                                <LazyImage src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1594304772/New-home-screen/Group_582_ypzywa.svg" alt="toggle" />
                                :
                                <LazyImage src="https://res.cloudinary.com/onsurity/image/upload/v1639550944/New-home-screen/Group_8170_pry7q7.svg" alt="toggle" />
                            }
                        </span>
                        <div className="d-flex justify-content-between">
                            <a href={urls.HOME} className="logo">
                            <LazyImage  alt="logo" src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1595591334/New-home-screen/Group_1328_bbme78.svg" onClick={() => props.setNavSelected(navigation.HOME)} className="onsuimg" />
                            </a>
                            {props.auth.authStatus === true ?
                                <div className="mr-5">
                                    <button id="login-link"
                                    onClick={() => SetMyaccToggle()}
                                    className="ml-0  nav-links">My Account</button>
                                    </div>
                                :
                                <button id="myaccount" className="nav-links"
                                    onClick={() => {
                                        props.history.push(urls.VERIFY_PHONE)
                                        Clevertap('web_sent_otp_purchase', { clicked: true })
                                    }}>{IsRedBus()} </button>
                            }
                        </div>
                        <ul ref={ref} className="main-navigation" id="js-menu">
                        <li className="liLink accordion-wrapper">
                                <a  href="https://health.onsurity.com/credits-program" alt="Credits Program"  id="navv1" target="_blank" className="nav-links">Credits Program</a>
                            </li>
                            <section className="accordion-wrapper">
                                <span onClick={() => setActive(active === 0 ? "" : 0)}>
                                    <section className="px-5" style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #C6CBD4", paddingBottom: "1rem"}} onClick={() => { }} class="accordion">
                                        <span>Already a member?</span>
                                        <LazyImage className="nav-drop" src={DropDownIcon} />
                                    </section>
                                </span>
                                <section className={`accordion ${active === 0 ? "d-block" : "d-none"}`}>
                                    <div >

                                        <ul className=" home-list-member home-list-member-mobile" style={{}}>
                                            <li style={{ marginBottom: '8px'}}>
                                                <p>Teamsure dashboard</p>
                                                <button onClick={() => {
                                                    HandleDanshBoard()
                                                }}>Login</button>
                                            </li>
                                            <li > <p>Onsurity app</p>
                                            <a href="/#app-download">
                                                    <button >Get the app</button>
                                                </a>
                                                </li>
                                        </ul>
                                    </div>
                                </section>
                            </section>
                            <section className="accordion-wrapper">
                                <span onClick={() => setActive(active === 1 ? "" : 1)}>
                                    <section className="px-5" style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #C6CBD4", paddingBottom: "1rem" }} onClick={() => { }} class="accordion">
                                        <span className={`${benifitsUri.includes(location.pathname) && "selectedNav"}`}>Benefits</span>
                                        <LazyImage className="nav-drop" src={DropDownIcon} />
                                    </section>
                                </span>
                                <section className={`accordion ${active === 1 ? "d-block" : "d-none"}`}>
                                    <div className="">

                                        <ul className=" home-list-member home-list-member-mobile" style={{}}>

                                            <li onClick={() => props.history.push(urls.GROUP_HEALTH_NEW)} className={`homeListLi ${location.pathname === urls.GROUP_HEALTH_NEW && "selectedNav"}`}>Group Health Insurance</li>
                                            <li onClick={() => props.history.push(urls.TERM_LIFE_NEW)} className={`homeListLi ${location.pathname === urls.TERM_LIFE_NEW && "selectedNav"}`}>Group Term Life Insurance</li>
                                            <li onClick={() => props.history.push(urls.GOOD_DOCTOR_NEW)} className={`homeListLi ${location.pathname === urls.GOOD_DOCTOR_NEW && "selectedNav"}`}>Claims Concierge</li>
                                            <li onClick={() => props.history.push(urls.FITNESS_TRACKING_NEW)} className={`homeListLi ${location.pathname === urls.FITNESS_TRACKING_NEW && "selectedNav"}`}>Fitness & Wellness Benefits</li>

                                        </ul>
                                    </div>
                                </section>
                            </section>

                            <section className="accordion-wrapper">
                                <span onClick={() => setActive(active === 2 ? "" : 2)}>
                                    <section className="px-5" style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #C6CBD4", paddingBottom: "1rem" }} onClick={() => { }} class="accordion">
                                        <span className={`${location.pathname === urls.ABOUT_US && "selectedNav"}`}>About</span>
                                        <LazyImage className="nav-drop" src={DropDownIcon} />
                                    </section>
                                </span>
                                <section className={`accordion ${active === 2 ? "d-block" : "d-none"}`}>
                                    <div className="">

                                        <ul className=" home-list-member home-list-member-mobile" style={{}}>

                                            <li onClick={() => props.history.push(urls.ABOUT_US)} className={`homeListLi ${location.pathname === urls.ABOUT_US && "selectedNav"}`}>About Us</li>
                                            <li onClick={() => window.open('https://health.onsurity.com/newsroom/')} className={`homeListLi`}>Newsroom</li>
                                          
                                        </ul>
                                    </div>
                                </section>
                            </section>
                            <li className="liLink accordion-wrapper">
                                <a href='https://onsurity.kekahire.com/' id="navv1" target="_blank" className="nav-links">Careers</a>
                            </li>
                            <li className="liLink accordion-wrapper">
                                <span onClick={() => { props.history.push(urls.CONTACT_US); HandleToggle() }} id="navv" className={`nav-links ${location.pathname === urls.CONTACT_US_NEW && "selectedNav"}`} >Contact Us</span>
                                <a title="Onsurity – Contact Us for Customer Support and Partnerships" href="https://www.onsurity.com/teamsure/contact-us" alt="Contact Us" style={{ fontSize: '0px' }}>Contact Us</a>
                            </li>


                            <li className="liLink accordion-wrapper">
                                <a href={urls.BLOG_DESC} target="_blank" id="navv" className={`nav-links ${props.nav_selected == navigation.BLOG && "selectedNav"}`} >Blog</a>
                            </li>
       
                            <div className="mobile-sidebar resp-right mr top-mar-ico ft_disp_flex rmvPad_ft">
                                <a href="https://www.facebook.com/onsurity/" onClick={(e) => { fbClkFn(e) }} target="_blank" rel="noopener noreferrer" className=" ft_btns_rgt ftMar0px">
                                    {<LazyImage height="40px" className="lazyload" src="https://res.cloudinary.com/onsurity/image/upload/v1594119907/New-home-screen/faceBkIcnFtr_jol7zd.svg" alt="social-icons" />}
                                </a>
                                <a href="https://www.instagram.com/onsurity_/" target="_blank" rel="noopener noreferrer" className="mx-2 ft_btns_rgt">
                                    {<LazyImage height="40px" className="lazyload" src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1594119907/New-home-screen/InstaIcnFtr_dt2i5p.svg" alt="social-icons" />}
                                </a>
                                <a href="https://twitter.com/onsurity" onClick={(e) => { twitterClkFn(e) }} target="_blank" rel="noopener noreferrer" className="mx-2 ft_btns_rgt">
                                    {<LazyImage height="40px" className="lazyload"
                                        src="https://res.cloudinary.com/onsurity/image/upload/v1594119907/New-home-screen/TwtrIcnFtr_l56qey.svg"
                                        alt="social-icons" />}
                                </a>
                                <a href="https://www.linkedin.com/company/onsurity/" target="_blank" rel="noopener noreferrer" className="mx-2 ft_btns_rgt">
                                    {<LazyImage height="40px" className="lazyload" src="https://res.cloudinary.com/onsurity/image/upload/fl_lossy,f_auto,q_auto/v1594119908/New-home-screen/LinkedInIcnFtr_plf3fs.svg" alt="social-icons" />}
                                </a>
                            </div>
                        </ul>

                    </nav>
                </div>
            </div>
        </>

    )
}

const mapStateToProps = (state) => ({
    dashboard: state.userstate,
    state: state.userstate.verify,
    name: state.userstate.companyDetails.Name,
    auth: state.navigationStack.authObj,
    nav_selected: state.userstate.navigationSelected,
    logoutSession: state.navigationStack.logOutData,
    whatsapp: state.userstate.whatsapp,
})

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        ...navigationActions,
        ...user_state,
        ...resetCompany
        //ADD IF ITS FROM DIFFRENT ACTION FILE
    }, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
