export const types={
    SET_CART:'CART/SET_CART',
    SET_CART_SUCCESS:'CART/SET_CART_SUCCESS',
    SET_CART_FAIL:'CART/SET_CART_FAIL'
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.SET_CART:
            return { ...state, isLoading:true, data: null}

      case types.SET_CART_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.SET_CART_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

      default:
        return state
    }
  }

  export const actions={
      setCart:(reqtype, data, method="post")=>({type:types.SET_CART, reqtype, data, method})
  }
