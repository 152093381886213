
import { all } from 'redux-saga/effects';
import { authWatcher } from './auth';
import { gstWatcher } from './gst';
import { search_companyWatcher } from './search_company';
import { purchaseWatcher, purchaseVerifyOTP, LogoutSession, WhatsappPatch } from './navigation_stack';
import {plansWatcher} from './plan';
import { cartWatcher } from './cart';
import { faqWatcher } from './faq';
import { inviteAppWatcher } from './invite_app';
import { StaticPricingWatcher } from './static_pricing';
import { gstVerifyWatcher } from './verifyGst';
import { paymentOptionWatcher } from './payment_options';
import { orderWatcher } from './order';
import { verifyOrderWatcher } from './verify_order';
import { cavarageWatcher } from './coverages';

export default function* rootSaga() {
    yield all([
        authWatcher(),
        gstWatcher(),
        purchaseWatcher(),
        purchaseVerifyOTP(),
        plansWatcher(),
        search_companyWatcher(),
        cartWatcher(),
        faqWatcher(),
        inviteAppWatcher(),
        StaticPricingWatcher(),
        gstVerifyWatcher(),
        paymentOptionWatcher(),
        orderWatcher(),
        verifyOrderWatcher(),
        LogoutSession(),
        WhatsappPatch(),
        cavarageWatcher()
    ]);
 }
