import React from 'react'
import Slider from 'react-slick'
import './slider.scss';

const CustomSlider = ({children,className="",...props}) => {
    return (
        <div className={`sliderWrapper ${className}`}>
        <Slider dots={true} speed={500} slidesToShow={2}  slidesToScroll={1} {...props}>
            {children}
        </Slider>
        </div>
    )
}

export default CustomSlider
