import { urls } from "../../routes/routes"
import {store} from '../../configureStore'
import {types} from '../../redux/ducks/state_change'
import { types as logoutTypes } from '../../redux/ducks/navigation_stack'
const Logout=(props)=>{

    store.dispatch({type:types.RESET_STATE})
    store.dispatch({type:logoutTypes.LOGOUT_REQUEST})
    store.dispatch({type:logoutTypes.RESET_AUTH_OBJ})

    localStorage.setItem('surity:log', '0');
    localStorage.removeItem("token");

    setTimeout(function () {
        store.dispatch({type:logoutTypes.RESET_AUTH_OBJ})
    }, 500)

    let lgdata = localStorage.getItem("LG");

    if (lgdata !== undefined || lgdata !== null) {
        localStorage.setItem("LG", 0);
        setTimeout(() => {
            window.location.reload();
        }, 1000)
    }
    window.location.href=urls.VERIFY_PHONE
} 



export default Logout