import { put, takeLatest } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/auth'
import axios from 'axios'

//GET EMP 
function* fetchAuth() {
    try{
        const response = yield axios.get(`http://dummy.restapiexample.com/api/v1/employees`)
        
         yield put({ type: actionTypes.SIGNUP_SUCCESS, users:response.data.data});
           
    }catch{
        yield put({ type: actionTypes.SIGNUP_FAILURE});
    }
  
} 
export function* authWatcher() {
     yield takeLatest(actionTypes.SIGNUP_REQUEST, fetchAuth)
}
