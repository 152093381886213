import React from "react";

// Styles
import "./Testimonial.scss";

function Testimonial({ testimonialContent, author }) {
  const { imageUrl, name, organization, designation } = author;

  return (
    <div className="testimonial">
      <div className="testimonial-content-wrapper">
        <p>{testimonialContent}</p>
      </div>

      <div className="testimonial-author-info-wrapper">
        <div className="author-image">
          <img src={imageUrl} alt="Author" />
        </div>
        <div className="author-info">
          <p className="name">{name}</p>
          <p className="organization">{organization}</p>
          <p className="designation">{designation}</p>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
