import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
//for react snp prerender
import { hydrate, render } from "react-dom";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

//image lazy load
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react'

import {store , persistor} from './configureStore'
// import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    // gtmId: 'GTM-K38DDLS',
    // gtmId: 'GTM-MHPXD3B',
    gtmId: 'GTM-KVM2K39',

}
 
TagManager.initialize(tagManagerArgs)

// Sentry.init({dsn: "https://5922fb6e6aae4563b6585974efd16fb6@o310436.ingest.sentry.io/5203667"});

const rootElement = document.getElementById("root");


const Main=(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <React.StrictMode>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </React.StrictMode>
    </PersistGate>
  </Provider>
)

if (rootElement.hasChildNodes()) {
  hydrate(Main, rootElement);
} else {
  render(Main, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

