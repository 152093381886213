export const OnsurityUrls={
    HOME:'/',
    VERIFY_PHONE_OLD:"/teamsure/verify",
    VERIFY_PHONE:"/checkout/verify",
    VERIFY_OTP:"/checkout/verify-otp",
    GSTIN_DETAILS:"/checkout/gst-details",
    COMPANY_DETAILS:"/checkout/company-details",
    SELECT_PLAN:"/checkout/plan-select",
    ADD_PEOPLE:"/checkout/add-people",
    CHECKOUT:"/checkout/checkout",
    PAYMENT:"/checkout/payment",
    SUMMARY_MOB: "/checkout/summary",
    // DETAILS: "/teamsure/details", deleted
    // COVERAGES: "/checkout/coverages",
    FAQ:"https://onsurity.com/faq/",
    FAQ_OLD:"/teamsure/faq",
    BLOG_OLD:"/teamsure/blog",
    BLOG_OLD_OLD:"/teamsure/discover",
    BLOG_DESC: "https://blog.onsurity.com/",
    BLOG_REDIRECT: "/discover",

    CONTACT_US: "/contact",
    CONTACT_US_APP: "/teamsure/contact-us",
    CONTACT_US_NEW:"/contact-us",


    PRIVACY_POLICY_DUP: "/teamsure/privacy",
    PRIVACY_POLICY: "/privacy-policy",
    PRIVACY_POLICY_MOB: "/mobile/privacy-policy",

    
    Terms_USE: "/terms-of-use",
    Terms_USE_DUP: "/teamsure/termsofuse",
    TERMS_OF_USE_MOB: "/mobile/terms-of-use",

    PAYMENT_VERIFICATION: "/teamsure/verify-payment",

    ABOUT_US: "/about-us",
    ABOUT_US_OLD: "/teamsure/about-us",
    ABOUT_US_MOBILE: "/mobile/about",

    PAYMENT_FAIL: "/teamsure/payment-fail",
    PAYMENT_PENDING: "/teamsure/payment-pending",

    // CAREER: "/teamsure/career", deleted
    // NOTFOUND: "/404", not found deleted
    OOPS_NOTFOUND: "/404",

    FITNESS_TRACKING_OLD:"/teamsure/fitnessrewards-details",
    FITNESS_TRACKING: "/teamsure/fitnesstracking-details", 
    FITNESS_TRACKING_NEW: "/employee-healthcare/fitness-tracking", 

    DISCOUNT_MEDICINE: "/teamsure/discountmedicine-details",
    DISCOUNT_MEDICINE_NEW: "/employee-healthcare/discounted-medicine",

    DOCTOR_CONUSLTATION: "/teamsure/doctorconsult-details",
    DOCTOR_CONUSLTATION_NEW:"/employee-healthcare/doctor-consultation",

    HEALTH_CHECKUP: "/teamsure/healthcheckup-details",
    HEALTH_CHECKUP_NEW: "/employee-healthcare/discounted-health-checkups",

    GROUP_HEALTH_OLD:"/teamsure/group-health-insurance",
    GROUP_HEALTH: "/teamsure/grouphealthins-details",
    GROUP_HEALTH_NEW: "/employee-healthcare/group-health-insurance",

    GOOD_DOCTOR: "/teamsure/gooddoctor-details",
    GOOD_DOCTOR_NEW: "/employee-healthcare/good-doctors",

    TERM_LIFE: "/teamsure/groupterm-life-details",
    TERM_LIFE_NEW:"/employee-healthcare/group-term-life-insurance",
    
    PRODUCT_PAGE:"/teamsure/product",
    PRODUCT_PAGE_NEW:"/employee-healthcare-benefits",
    
    BLOG: "/blog",
    APP:'/app',

    LETS_VENTURE: "/sequoia",
    LETS_VENTURE_NEW: "/venture-capitals/sequoia",

    MATRIX_PARTNER: "/matrix-partners",
    MATRIX_PARTNER_NEW: "/venture-capitals/matrix-partners",

    LIGHT_SPEED_VENTURE : "/light-speed-ventures",
     LIGHT_SPEED_VENTURE_NEW : "/venture-capitals/light-speed-ventures",

    CHIRATE_VENTURE: "/chiratae-ventures",
    CHIRATE_VENTURE_NEW: "/venture-capitals/chiratae-ventures",

    NEXUS_VENTURE: "/nexus-venture-partners",
    NEXUS_VENTURE_NEW: "/venture-capitals/nexus-venture-partners",

    ANGELIST: "/angellist",
    ANGELIST_NEW: "/venture-capitals/angellist",

    FLOURISH_VENTURE: "/flourish-ventures",
    FLOURISH_VENTURE_NEW: "/venture-capitals/flourish-ventures",

    ACCION: "/accion",
    ACCION_NEW: "/venture-capitals/accion",

    OMIDYAR_NETWORK: "/omidyar-network",
    OMIDYAR_NETWORK_NEW: "/venture-capitals/omidyar-network",

    MAYFIELD_VENTURE: "/mayfield-ventures",
    MAYFIELD_VENTURE_NEW: "/venture-capitals/mayfield-ventures",

    INDIAN_NETWORK: "/Indian-angel-network",
    INDIAN_NETWORK_NEW: "/venture-capitals/Indian-angel-network",

    MUMBAI_NETWORK: "/mumbai-angel-network",
    MUMBAI_NETWORK_NEW: "/venture-capitals/mumbai-angel-network",

    KAE_CAPITAL: "/kae-capital",
    KAE_CAPITAL_NEW: "/partnerships/kae-capital",

    STARTUP: "/startupawardwinners",
    STARTUP_NEW: "/partnerships/startupawardwinners",

    TECHTHIRTY: "/techsparks30",
    TECHTHIRTY_NEW: "/partnerships/techsparks30",

    REDBUS: "/redbus-partnership",
    REDBUS_NEW: "/partnerships/redbus-partnership",

    SEPEQTA: "/speqta-gst",
    SEPEQTA_NEW: "/partnerships/speqta-gst",

    GIN_SERVE: "/ginserv",
    GIN_SERVE_NEW: "/partnerships/ginserv",

    SPRING_WORKS: "/springworks",
    SPRING_WORKS_NEW: "/partnerships/springworks",

    CLUB_INDIA: "/caclubindia",
    CLUB_INDIA_NEW: "/partnerships/caclubindia",

    LEAD_ANGELS: "/leadangels",
    LEAD_ANGELS_NEW: "/partnerships/leadangels",

    QUIKR: "/quikr",
    QUIKR_NEW: "/partnerships/quikr",

    REDIRECT_1MG: "/mobile/1mg",
    TESTIMONIAL:"/reviews",
    CAMPARISION : "/onsurity-vs-market-alternatives",

    // PARTNER
    PARTNER_URL: '/partner'
  }
