import Axios from "axios";
import { isSameDomain } from "../layouts/PlanPurchase/util";
import { urls } from "../routes/routes";
import { urlPrefixEmpSure, urlPrefixGlobal } from "./MicroServiceURL";

export const ResetData=()=>{
    window.location.href='/'
}



export const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};


export const Clevertap=(objName, obj)=>{
    if(isSameDomain(document.referrer)){
        obj.channel_partner='Godrej'
    }
    if(window.clevertap){
        window.clevertap.event.push(objName, {...obj});
        return true
    }else{
        return false
    }
}

export const ClevertapUserProfile=(objName, obj)=>{

    if(window.clevertap){
        window.clevertap.profile.push({
            "Site":{
                ...obj
            }
        });
        return true
    }else{
        return false
    }
}


export const ShowCoverage=()=>{
    let showCoverage=false;
    return showCoverage;
}

export const IsRedBus=()=>{
    if(urls.REDBUS == window.location.pathname || urls.GIN_SERVE == window.location.pathname || urls.LEAD_ANGELS == window.location.pathname){
        return "Buy Now"
    }
    else if(urls.CLUB_INDIA == window.location.pathname){
        return "Purchase now"
    }else{
        return "Purchase now"
    }
}
const getCookieValue = (name) => (

    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
    
    )

export const Hobspot={
       push:(payload)=>{
        Axios.post(urlPrefixEmpSure('/external/integrations/hubspot'),{...payload,hutk:getCookieValue("hubspotutk")},
            { 
                headers:{
                    Authorization:`bearer ${localStorage.getItem('token')}`
                }
            })
            .then(res=>{})
            .catch(err=>{})
       }
}

export const GetTermsAndPrivacy=(version="")=>{
    return new Promise((resolve, reject)=>{
        Axios.get(urlPrefixGlobal(`/tnc?version=${version}`), 
        { 
        headers:{
            platform:'web'
            },
        })
        .then(res=>{
        
        if(res.data.success===1){
            const { data }=res.data;
            resolve(data)
         }
        })
        .catch(err=>{
            reject("error occured")
        })  
    })
}

export const isMobileDevice=()=>{
   return  (window.innerHeight <= 800);
}


export const groupArray=(arr,groupBy)=>{
    if(!arr || !arr.length ) return []
    let newArry=[]
    let i=0
    let tempArr=[]
    while (i < arr.length) {
        if(tempArr.length===groupBy || i === arr.length-1){
            if(i === arr.length-1){
                tempArr.push(arr[i])
            }
            
            newArry.push(tempArr)
            tempArr=[]
        }
        if(arr[i]){

            tempArr.push(arr[i])
        }
        i++
    }
    
    return newArry

}

export const redirect = () => {
    const redirection = (localStorage.getItem('from'))
                           
   const checkFromExist = ()=> {
        
        if(redirection) {
        return true
            
        } else {
            return false
        }
   }

    return {
        redirectToFromPath: () => window.location.href = `https://${redirection}`,
        checkFromPathExist: ()=>checkFromExist()
    }
}