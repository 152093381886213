export const types={
    INVITE_APP:'HOME/INVITE_APP',
    INVITE_APP_SUCCESS:'HOME/INVITE_APP_SUCCESS',
    INVITE_APP_FAIL:'HOME/INVITE_APP_FAIL'
}

export const initialState = {
    data: null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.INVITE_APP:
            return { ...state, isLoading:true, data: null, error:false}

      case types.INVITE_APP_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.INVITE_APP_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}


      default:
        return state
    }
  }

  export const actions={
      inviteApp:(pMobNum)=>({type:types.INVITE_APP, pMobNum})
  }
