export const types={
    FETCH_GSTIN:'GST/FETCH_GSTIN',
    FETCH_GSTIN_SUCCESS:'GST/FETCH_GSTIN_SUCCESS',
    FETCH_GSTIN_FAIL:'GST/FETCH_GSTIN_FAIL',
    UPDATE_GSTIN:'GST/UPDATE_GSTIN'
}

export const initialState = {
    gstin:'',
    data:null,
    isLoading: false,
    error: null,
    isSezData:false
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.FETCH_GSTIN:
            return { ...state, gstin:action.gst, isLoading:true, data: null,isSezData:false}

      case types.FETCH_GSTIN_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data,isSezData:action.data?.data.is_sez}

      case types.FETCH_GSTIN_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data,isSezData:false}

      case types.UPDATE_GSTIN:
        return { ...state, isLoading:false, error:false, data:action.data,isSezData:action.data?.data.is_sez}
  
      default:
        return state
    }
  }

  export const actions={
      fetchGstin:(gst)=>({type:types.FETCH_GSTIN, gst}),
      updateGstin:(data)=>({type:types.UPDATE_GSTIN, data}),
  }
