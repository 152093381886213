import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/search_company';
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';

//GET EMP
function* fetchCompany(action) {
    try{
        let searchCmpUrl = pageURL.SEARCH_COMPANY;//"https://run.mocky.io/v3/39e72f92-5a9f-43ef-bad0-bb95c0eed758";
        //pageURL.FETCH_COMPANY;
        searchCmpUrl = searchCmpUrl.replace('~COMPANY_NAME~', action.data);
        const response = yield call (apiCall, "get", searchCmpUrl);
         
        if(response.data.success===1){
         yield put({ type: actionTypes.FETCH_COMPANY_SUCCESS, data:{status:1, data:response.data}});
        }
        else{
            yield put({ type: actionTypes.FETCH_COMPANY_FAIL, data:{status:0, data:response.data}});
        }

    }catch(e){
        if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.FETCH_COMPANY_FAIL, data:{status:0, data:e.response.data}});
        }
        else {
          yield put({ type: actionTypes.FETCH_COMPANY_FAIL, data:{status:0, data:{msg:g_Failed}}});
        }
    }

}
export function* search_companyWatcher() {
     yield takeLatest(actionTypes.FETCH_COMPANY, fetchCompany)
}
