import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/cart'
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';
import { convertUrlToSourceName } from '../../layouts/PlanPurchase/util';

//GET EMP
function* getCart(action) {
    try{
        let payload={
            type:action.reqtype,
            item:action.data
        }
        let cartUrl=pageURL.CART_URL;
        let cartUrlGet=pageURL.CART_URL_GET_DETAILS;
        let response=null;
        if(action.method==="get"){
            response= yield call (apiCall, "get", cartUrlGet);
        }else{
            response= yield call (apiCall, "post", cartUrl, payload);
        }

        if(response.data.success===1){
         yield put({ type: actionTypes.SET_CART_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.SET_CART_FAIL, data:response.data});
        }

    }catch(e){
        if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.SET_CART_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.SET_CART_FAIL, data:g_Failed});
        }
    }

}
export function* cartWatcher() {
     yield takeLatest(actionTypes.SET_CART, getCart)
}
