import React from "react";
import { Collapse } from 'antd';
import './collapseCard.scss'

const { Panel } = Collapse;


const CollapseCard=(props)=>{


    return (
    <Collapse accordion>
      {props?.data?.data?.map((item,index)=><Panel header={item?.question} key={`${index+1}`}>
        <p className="collapse-card-ans-top-div">{item?.ans?.label}</p>
        <ul>
        {item?.ans?.allPoints?.map((itemData,index)=><li>
              {itemData?.value}
              {itemData.isNested && <ul>
                  {itemData?.morePoint?.map(itemValue=><li>
                    {itemValue}
                  </li>)}
              </ul>

              }
        </li>)

        }
        </ul>
        <p>{item?.extratext}<span><a target="_balnk" href={item?.locationurl}>{item?.locationurl}</a></span></p>
      </Panel>)

      }
      </Collapse>
      )
}

export default CollapseCard