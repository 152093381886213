import { put, takeLatest, call } from 'redux-saga/effects';
import {types as actionTypes} from '../ducks/faq'
import {pageURL} from '../../utils/MicroServiceURL';
import {apiCall} from '../../utils/Api';
import {g_Failed} from '../../utils/Constants';

//GET EMP
function* fetchFAQ(action) {
    try{
        let gstUrl=pageURL.FAQ_URL;
        const response = yield call (apiCall, "get", gstUrl);
        if(response.data.success===1){
         yield put({ type: actionTypes.FETCH_FAQ_SUCCESS, data:response.data});
        }
        else{
            yield put({ type: actionTypes.FETCH_FAQ_FAIL, data:response.data});
        }
    }catch(e){
         if(!!e.response && !!e.response.data)
        {
          yield put({ type: actionTypes.FETCH_FAQ_FAIL, data:e.response.data});
        }
        else {
          yield put({ type: actionTypes.FETCH_FAQ_FAIL, data:g_Failed});
        }
    }

}
export function* faqWatcher() {
     yield takeLatest(actionTypes.FETCH_FAQ, fetchFAQ)
}
