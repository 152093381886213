export const types={
    VERIFY_GSTIN:'GST/VERIFY_GSTIN',
    VERIFY_GSTIN_SUCCESS:'GST/VERIFY_GSTIN_SUCCESS',
    VERIFY_GSTIN_FAIL:'GST/VERIFY_GSTIN_FAIL',
    RESET_VERIFY_GSTIN:'GST/RESET_VERIFY_GSTIN',
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null,
    gst:'',
    gstVerified:false,
    isSez:false
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.VERIFY_GSTIN:
            return { ...state, isLoading:true, data: null, gstVerified:false, gst:action.gst}

      case types.VERIFY_GSTIN_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data, gstVerified:true,isSez:action.data?.data.is_sez
            }

      case types.VERIFY_GSTIN_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data, gstVerified:false,isSez:false}
      
      case types.RESET_VERIFY_GSTIN:
            return { ...state, isLoading:false, error:null, data:null, gstVerified:false,isSez:false}

      default:
        return state
    }
  }

  export const actions={
      verifyGstin:(gst)=>({type:types.VERIFY_GSTIN, gst}),
      setVerifyGstin:()=>({type:types.RESET_VERIFY_GSTIN})
  }
