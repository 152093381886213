export const types={
    FETCH_FAQ:'FAQ/FETCH_FAQ',
    FETCH_FAQ_SUCCESS:'FAQ/FETCH_FAQ_SUCCESS',
    FETCH_FAQ_FAIL:'FAQ/FETCH_FAQ_FAIL'
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.FETCH_FAQ:
            return { ...state, isLoading:true, data: null}

      case types.FETCH_FAQ_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.FETCH_FAQ_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

      default:
        return state
    }
  }

  export const actions={
      fetchFaq:()=>({type:types.FETCH_FAQ}) 
  }
