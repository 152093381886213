export const types={
    FETCH_STATIC_PRICING:'GST/FETCH_STATIC_PRICING',
    FETCH_STATIC_PRICING_SUCCESS:'GST/FETCH_STATIC_PRICING_SUCCESS',
    FETCH_STATIC_PRICING_FAIL:'GST/FETCH_STATIC_PRICING_FAIL'
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.FETCH_STATIC_PRICING:
            return { ...state,  isLoading:true, data: null}

      case types.FETCH_STATIC_PRICING_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.FETCH_STATIC_PRICING_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

      default:
        return state
    }
  }

  export const actions={
      fetchStaticPricing:(data)=>({type:types.FETCH_STATIC_PRICING, data})
  }
