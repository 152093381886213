export const types={
    SET_ORDER:'GST/SET_ORDER',
    SET_ORDER_SUCCESS:'GST/SET_ORDER_SUCCESS',
    SET_ORDER_FAIL:'GST/SET_ORDER_FAIL'
}

export const initialState = {
    data:null,
    isLoading: false,
    error: null
  }

  export default (state = initialState, action) => {
    switch (action.type) {
      case types.SET_ORDER:
            return { ...state, isLoading:true, data: null}

      case types.SET_ORDER_SUCCESS:
            return { ...state, isLoading:false, error:false, data:action.data}

      case types.SET_ORDER_FAIL:
            return { ...state, isLoading:false, error:true, data:action.data}

      default:
        return state
    }
  }

  export const actions={
      putOrder:(obj)=>({type:types.SET_ORDER, obj})
  }
