

//CALLBACK WHICH NEED TO RETURN ONCE PAYMENT IS SUCCESSFULL
export const CallBackUrl=()=>{
    var url = window.location.href;
    var arr = url.split("/");
    var result = arr[0] + "//" + arr[2]+"/teamsure/verify-payment";
    return(result)
}

export const DashboardUrl="https://teams.onsurity.com/verify"

export const provider='hdfc_ergo';

//CHANGING THIS WILL CLEAR ALL THE LOCALSTORAGE
export const CurrentVersion='1.0.2'

//THESE ARE ITEMS PRESENT IN CART
export const CheckoutItems=['ES', 'CN', 'TX', 'DS', 'VR', 'WA']

//NAVGIATION SELECTED ITEMS KEY
export const navigation={
    HOME:'HOME',
    ABOUT:'ABOUT',
    CONTACT:'CONTACT',
    BLOG:'BLOG',
    FAQ:'FAQ'
}
